import * as Yup from 'yup';
import {
  PROFILE_FIRST_NAME_VALIDATION_SCHEMA,
  PROFILE_LAST_NAME_VALIDATION_SCHEMA,
} from '../../schemas/validations/profile';
import { EMAIL_VALIDATION_SCHEMA } from '../../schemas/validations/email';
import {
  ProfileUpdateRequestData,
  PersonalInfo,
  LicenseInfo,
  CompanyInfo,
  GenderTypes,
  UsaStates,
  ServicesInfo,
  AdditionalPhoneNumber,
} from '../../types';
import { PHONE_INPUT_SCHEMA } from '../../schemas/validations/phoneInput';
import { URL_VALIDATION_SCHEMA } from '../../schemas/validations/url';
import { BrochuresCounts } from '../fields/BrochuresCountFormField/BrochuresCountFormField';
import { PHONE_NUMBER_VALIDATION_SCHEMA } from '../../schemas/validations/phone';

export const ProfileFormSchema = Yup.object().shape<ProfileUpdateRequestData>({
  personal: Yup.object()
    .shape<Omit<PersonalInfo, 'phone_number' | 'phone_number_country'>>({
      firstname: PROFILE_FIRST_NAME_VALIDATION_SCHEMA,
      lastname: PROFILE_LAST_NAME_VALIDATION_SCHEMA,
      country: Yup.string().required('Please enter a country'),
      state: Yup.mixed<UsaStates>().when(['country'], {
        is: 'US',
        then: (schema: Yup.StringSchema) =>
          schema.required('Please enter a state'),
      }),
      city: Yup.string()
        .required('Please enter a city')
        .min(2, ({ min }) => `City must be at least ${min} characters`)
        .max(
          38,
          ({ max }) => `City should not be longer than ${max} characters`
        ),
      zip_code: Yup.string().when(['country'], {
        is: 'US',
        then: (schema: Yup.StringSchema) =>
          schema
            .min(5, ({ min }) => `Zip code must be at least ${min} characters`)
            .required('Please enter a zip code'),
      }),
    })
    .concat(PHONE_INPUT_SCHEMA.required())
    .concat(
      Yup.object({
        gender: Yup.mixed<GenderTypes>(),
      })
    ),
  license: Yup.object().shape<LicenseInfo>({
    occupation: Yup.string()
      .min(2, ({ min }) => `Occupation must be at least ${min} characters`)
      .max(
        50,
        ({ max }) => `Occupation should not be longer than ${max} characters`
      )
      .required('Please enter an occupation'),
  }),
  company_info: Yup.object()
    .shape<
      Omit<
        CompanyInfo,
        'phone_number' | 'phone_number_country' | 'phoneNumberType'
      >
    >({
      company: Yup.string().required('Please enter an company name'),
      email: EMAIL_VALIDATION_SCHEMA,
      website: URL_VALIDATION_SCHEMA,
      country: Yup.string().required('Please enter a country'),
      state: Yup.mixed<UsaStates>().when(['country'], {
        is: 'US',
        then: (schema: Yup.StringSchema) =>
          schema.required('Please enter a state'),
      }),
      city: Yup.string()
        .required('Please enter a city')
        .min(2, ({ min }) => `City must be at least ${min} characters`)
        .max(
          38,
          ({ max }) => `City should not be longer than ${max} characters`
        ),
      postal_code: Yup.string().when(['country'], {
        is: 'US',
        then: (schema: Yup.StringSchema) =>
          schema
            .min(5, ({ min }) => `Zip code must be at least ${min} characters`)
            .required('Please enter a zip code'),
      }),
      address: Yup.string().required('Please enter an address'),
      additional_phone_numbers: Yup.array().of(
        Yup.object({
          type: Yup.string().when('phone_number', {
            is: (phone_number) => phone_number && phone_number.length > 0,
            then: Yup.string().required(
              'Type is required when phone number is provided'
            ),
            otherwise: Yup.string().nullable(),
          }),
          phone_number: PHONE_NUMBER_VALIDATION_SCHEMA,
          phone_number_country: Yup.string().when('phone_number', {
            is: (phone_number) => phone_number && phone_number.length > 0,
            then: Yup.string().required(
              'Phone number country is required when phone number is provided'
            ),
            otherwise: Yup.string().nullable(),
          }),
        }) as Yup.Schema<AdditionalPhoneNumber>
      ),
    })
    .concat(PHONE_INPUT_SCHEMA.required()),
  services: Yup.object().shape<
    ServicesInfo & { brochures_countList: typeof BrochuresCounts }
  >({
    brochures_countList: Yup.mixed<typeof BrochuresCounts>().required(
      'Please specify the number of brochures'
    ),
    brochures_count: Yup.number()
      .nullable()
      .when('brochures_countList', {
        is: (brochuresCountList) => brochuresCountList === 1001,
        then: Yup.number().min(
          1001,
          ({ min }) => `Minimum number of brochures is ${min}`
        ),
      })
      .max(10000, ({ max }) => `Maximum number of brochures is ${max}`)
      .required('Please specify the number of brochures'),
  }),
});
