@import '../../scss/variables';

.default {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 $optionXPadding 12px;
  font-size: 13px;
  letter-spacing: 0.3px;
}

.title {
  margin: 0;
  white-space: nowrap;
}

.actionsContainer {
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
  cursor: pointer;
}

.actionOk:hover {
  opacity: 0.8;
}

.actionClear {
  padding-right: 8px !important;
}

.actionClear:hover {
  color: hsl(0, 0%, 20%);
}
