import React, { useCallback } from 'react';
import { Field } from 'formik';

import {
  AmountFormFieldType,
  AmountFormFieldWrapperType,
} from './AmountFormField.types';
import { InputField } from '../../../Input';
import { getFieldError } from '../../../../../utils/formik';

function AmountFormFieldWrapper({
  field,
  form,
  min = 0,
  max = 9999999.99,
  withCurrency = true,
  onChange,
  onFocus,
  onBlur,
  ...rest
}: AmountFormFieldWrapperType) {
  const currencySign = '$';
  const error = getFieldError(form, field.name);

  const handleHeyDown = useCallback((e) => {
    if (['e', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }
  }, []);

  const handleOnChange = useCallback(
    ({
      target: { value: targetValue },
    }: React.ChangeEvent<HTMLInputElement>) => {
      const newRawValue = targetValue
        .replace(/^([0-9]+[\\.,]?[0-9]{0,2}).*$/, '$1')
        .replace(',', '.');
      const amount = parseFloat(newRawValue) || 0;

      if (typeof max === 'number' && amount > max) {
        return;
      }

      if (typeof min === 'number' && amount < min) {
        return;
      }

      form.setFieldValue(field.name, amount, true);

      if (onChange) {
        onChange(amount);
      }
    },
    [field, form, onChange, max, min]
  );

  return (
    <InputField
      {...field}
      {...rest}
      type="text"
      onChange={handleOnChange}
      onKeyDown={handleHeyDown}
      prefix={withCurrency ? currencySign : undefined}
      error={error}
      step="any"
    />
  );
}

const AmountFormField = (props: AmountFormFieldType) => {
  return <Field {...props} component={AmountFormFieldWrapper} />;
};

export default AmountFormField;
