import { useMemo } from 'react';
import { AppclosePaymentType } from '../../types';

export default function usePaymentTypes() {
  const paymentTypesOptions = useMemo(
    () => [
      {
        label: 'Cash',
        value: AppclosePaymentType.CASH,
      },
      {
        label: 'Credit card',
        value: AppclosePaymentType.CREDIT_CARD,
      },
      {
        label: 'Check',
        value: AppclosePaymentType.CHECK,
      },
    ],
    []
  );

  const getPaymentTypeLabel = (paymentType: AppclosePaymentType) => {
    const option = paymentTypesOptions.find(
      (option) => option.value === paymentType
    );

    return option?.label || '';
  };

  return {
    paymentTypesOptions,
    getPaymentTypeLabel,
  };
}
