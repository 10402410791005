import React, { ReactNode, SyntheticEvent } from 'react';
import { ModifierArguments, State } from '@popperjs/core';

export type DropdownToggleSource = 'target' | 'outside' | 'ref' | 'content';

export interface DropdownPropsType {
  isOpen?: boolean;
  className?: string;
  container?: HTMLElement;
  content: (() => React.ReactElement) | ReactNode;
  disabled?: boolean;
  dropdownConfig?: (data: ModifierArguments<any>) => State;
  onDropdownToggle?: (value: boolean, source: DropdownToggleSource) => void;
  onToggleClick?: () => void;
  onContentClickHandler?(e: SyntheticEvent<HTMLElement, MouseEvent>): boolean;
  position?: PositionType;
  toggleComponent?: (toggleProps: {
    isDropdownOpen: boolean;
  }) => React.ReactElement;
  theme?: {
    content?: string;
  };
}

export type DropdownRefType = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

type PositionType =
  | 'right'
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';
