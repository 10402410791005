.addNew {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--color-border);
}

.addNew:hover {
  background-color: var(--color-utility);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  line-height: 1;
  background-color: var(--color-success);
  border-radius: 50%;
  box-shadow: 0 5px 10px rgba(0, 198, 129, 0.3);
}

.label {
  margin: 0 0 0 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.5px;
}
