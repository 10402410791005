import styles from './USAStateSelectFormField.module.scss';
import { OptionType } from '../../../../../ui/Select';

export const defaultUSAStateOptionLabelResolver = ({
  label,
  value,
}: OptionType) =>
  label && value ? (
    <>
      {label}
      <span className={styles.stateLabel}>{value}</span>
    </>
  ) : null;
