import { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import useCountries from '../../../hooks/useCountries';
import { SelectFormField, SelectFormFieldPropsType } from '../Select';

export type CountrySelectFormFieldPropsType = {
  name?: string;
  label?: string;
} & Omit<SelectFormFieldPropsType<string>, 'options' | 'name' | 'label'>;

export default function CountrySelectFormField({
  name = 'country',
  label = 'Country',
  placeholder = 'Select country...',
  ...rest
}: CountrySelectFormFieldPropsType) {
  const { countriesOptions } = useCountries();
  const [options, setOptions] = useState(countriesOptions);

  const onSearchChange = useMemo(
    () =>
      debounce((search: string = '') => {
        if (!search) {
          setOptions(countriesOptions);

          return;
        }

        const filtered = countriesOptions.filter((option) => {
          return option.label.toLowerCase().includes(search.toLowerCase());
        });

        setOptions(filtered);
      }, 500),
    [setOptions, countriesOptions]
  );
  const handleOnBlur = useCallback(() => {
    setOptions(countriesOptions);
  }, [countriesOptions]);

  return (
    <SelectFormField
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      {...rest}
      withSearch
      clearable={false}
      onSearchChange={onSearchChange}
      onBlur={handleOnBlur}
    />
  );
}
