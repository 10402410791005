import TrashIcon from 'jsx:./assets/trash.svg';

import { DeletableGroupPropsType } from './DeletableGroup.types';
import styles from './DeletableGroup.module.scss';
import { Button } from '../../../../../buttons';
import { Grid } from '../../../../../Grid';

export default function DeletableGroup({
  onDelete,
  children,
  enabled = true,
  position = 'center',
}: DeletableGroupPropsType) {
  if (!enabled) {
    return <>{children}</>;
  }

  return (
    <Grid
      className={styles.deletableGroup}
      columns="1fr 20px"
      gap={[15]}
      align={position}
    >
      {children}
      {enabled && (
        <Button className={styles.deleteButton} onClick={onDelete} skin="shell">
          <TrashIcon />
        </Button>
      )}
    </Grid>
  );
}
