import { FocusEventHandler, ReactNode } from 'react';

export type SelectType =
  | string
  | number
  | boolean
  | { [key: string]: any }
  | any;

export type OptionType<ValueType extends SelectType = any> =
  | {
      value: ValueType;
      label: ReactNode;
    }
  | ValueType
  | any;

export interface SelectPropsType<ValueType extends SelectType = any> {
  name?: string;
  value?: ValueType | ValueType[];
  iconClassName?: string;
  optionClassName?: string;
  labelClassName?: string;
  valueResolver?: (value: ValueType) => string;
  optionLabelResolver?: (option: OptionType<ValueType>) => ReactNode;
  optionValueResolver?: (option: OptionType<ValueType>) => ValueType;
  selectedOptionsResolver?: (
    selectedOptions: OptionType<ValueType>[],
    isMulti?: boolean
  ) => ReactNode | undefined;
  options?: OptionType<ValueType>[];
  defaultOptions?: OptionType<ValueType>[];
  disabled?: boolean;
  clearable?: boolean;
  hasError?: boolean;
  className?: string;
  placeholder?: string;
  isMulti?: boolean;
  withSearch?: boolean;
  onSearchChange?: (search: string) => void;
  isLoading?: boolean;
  optionsLoader?: ReactNode;

  /**
   * The async function for loading options
   * return true if it has more options, false otherwise
   */
  onLoadOptions?(): Promise<boolean>;
  onChange: (value?: ValueType | ValueType[]) => void;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  multipleOptionsTitle?: string;
  multipleClearButton?: string;
  multipleOkButton?: string;
  searchOptionsTitle?: string;
  optionsTitle?: string;
  optionsClearButton?: string;
  noOptionsTitle?: string;
  addLabel?: string;
  onAdd?(): void;
  onAddDisabled?: boolean;
  defaultOpen?: boolean;
}
