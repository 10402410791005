.field {
  width: 100%;
}

.hidden {
  display: none;
}

.showError {
  margin-bottom: -20px;
}


.withError {
  border-bottom: 1px solid var(--color-error) !important;
}


