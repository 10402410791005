import { ReactNode, ChangeEventHandler, FocusEventHandler } from 'react';

export interface CheckboxType {
  id?: string;
  name?: string;
  label?: ReactNode | string;
  defaultChecked?: boolean;
  readOnly?: boolean;
  checked?: boolean;
  theme?: 'light';
  align?: 'top' | 'bottom' | 'center';
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}
