.form {
  width: 100%;
  min-width: 536px;
}

.blockContainer {
  padding: 32px 12px;
}

.formContainer {
  padding: 0 20px;
}

.phoneContainer {
  display: flex;
}

.phoneInput {
  display: flex;
  align-items: flex-end;
  margin-left: 24px;
  flex-grow: 1;

  div {
    flex-grow: 1;
  }
}


.footer {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  gap: 12px;
}


@media only screen and (max-width: 600px) {
  .form {
    width: 300px;
    min-width: auto;
  }
}
