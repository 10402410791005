import { InputFormField } from '../../../Input';
import { ZipCodeFormFieldType } from './ZipCodeFormField.types';

export default function ZipCodeFormField({
  name = 'zipCode',
  label = 'Zip code',
  mask = '99999',
  ...props
}: ZipCodeFormFieldType) {
  return <InputFormField {...props} name={name} label={label} mask={mask} />;
}
