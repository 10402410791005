import classnames from 'classnames';

import styles from './FormGroup.module.scss';
import { FormGroupTypes } from './FormGroup.types';

export default function FormGroup({ children, className }: FormGroupTypes) {
  return (
    <div className={classnames(styles.formGroup, className)}>{children}</div>
  );
}
