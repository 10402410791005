import { FormGroup } from '../../../common/Form';
import { Fieldset } from '../../../common/Fieldset';
import { UpperFirstInputFormField } from '../../../fields/Input';
import { Block } from '../../../../ui/Block';
import { Column } from '../../../../ui/Column';
import GenderSelectFormField from '../../../fields/GenderSelectFormField';
import { AppclosePhoneField } from '../../../fields/AppclosePhoneField';
import FormGrid from '../../../common/FormGrid';
import { CountrySelectFormField } from '../../../fields/CountrySelectFormField';
import { USAStateSelectFormField } from '../../../fields/Select';
import { ZipCodeFormField } from '../../../fields/ZipCode';
import { USA_ZIP_CODE_MASK, ZIP_CODE_MASK } from '../../../../constants/masks';
import { ProfileUpdateRequestData } from '../../../../types';
import { useCallback } from 'react';

type CommonInfoFieldsetPropsType = {
  values: ProfileUpdateRequestData;
  setValues: (values: React.SetStateAction<ProfileUpdateRequestData>) => void;
};

export function CommonInfoFieldset({
  values,
  setValues,
}: CommonInfoFieldsetPropsType) {
  const isUSCountry = values.personal.country === 'US';

  const handleCountryChange = useCallback(
    (selectedCountry: string) => {
      if (selectedCountry !== 'US') {
        setValues((prevValues) => ({
          ...prevValues,
          personal: {
            ...prevValues.personal,
            state: '',
            zip_code: '',
          },
        }));
      }
    },
    [setValues]
  );

  return (
    <Block>
      <Fieldset title="Personal Information">
        <FormGroup>
          <Column gap={24}>
            <UpperFirstInputFormField
              name="personal.firstname"
              label="First name"
              placeholder="First name"
              required
            />
            <UpperFirstInputFormField
              name="personal.lastname"
              label="Last name"
              placeholder="Last name"
              required
            />
            <UpperFirstInputFormField
              name="personal.middlename"
              label="Middle name or initial (optional)"
              placeholder="Middle name or initial (optional)"
            />
            <GenderSelectFormField
              name="personal.gender"
              label="Gender"
              placeholder="Gender"
              required
            />
            <AppclosePhoneField
              codeName="personal.phone_number_country"
              phoneName="personal.phone_number"
              codeValue={values.personal.phone_number_country}
              phoneLabel="Phone number"
              disabled
              required
            />
            <FormGrid gap={24}>
              <CountrySelectFormField
                disabled
                name="personal.country"
                label="Country"
                placeholder="Country"
                required
                onChange={(selectedCountry: string) =>
                  handleCountryChange(selectedCountry)
                }
              />
              {isUSCountry && (
                <>
                  <USAStateSelectFormField
                    name="personal.state"
                    label="State"
                    placeholder="State"
                    required
                  />
                  <UpperFirstInputFormField
                    name="personal.city"
                    label="City"
                    placeholder="City"
                    required
                  />
                  <ZipCodeFormField
                    name="personal.zip_code"
                    mask={USA_ZIP_CODE_MASK}
                    label="Zip code"
                    placeholder="Zip code"
                    required
                  />
                </>
              )}
              {!isUSCountry && (
                <UpperFirstInputFormField
                  name="personal.city"
                  label="City"
                  placeholder="City"
                  required
                />
              )}
            </FormGrid>
          </Column>
        </FormGroup>
      </Fieldset>
    </Block>
  );
}
