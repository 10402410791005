import { LabelHTMLAttributes } from 'react';
import styles from './FieldLabel.module.scss';
import RequiredIcon from '../../../../../assets/icons/required.svg';

type FieldLabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  required?: boolean;
};

const FieldLabel: React.FC<FieldLabelProps> = ({
  children,
  required,
  ...props
}) => {
  return (
    <label className={styles.label} {...props}>
      <>{children}</>
      {required && (
        <img
          width={12}
          height={12}
          src={RequiredIcon}
          alt="required"
          className={styles.icon}
        />
      )}
    </label>
  );
};

export default FieldLabel;
