import { useCallback } from 'react';

import styles from './OptionsHeader.module.scss';
import { OptionsHeaderType } from './OptionsHeader.types';

export default function OptionsHeader({
  optionsTitle,
  hasSearchValue,
  optionsClearButton,
  onClearClick,
}: OptionsHeaderType) {
  const handleClearClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onClearClick();
    },
    [onClearClick]
  );

  return (
    <div className={styles.default}>
      <div className={styles.title}>{optionsTitle}</div>
      <div className={styles.actionsContainer}>
        {hasSearchValue && (
          <div className={styles.actionClear} onClick={handleClearClick}>
            {optionsClearButton}
          </div>
        )}
      </div>
    </div>
  );
}
