import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column } from '../../ui/Column';
import { Header } from '../Header';
import { SignInForm } from '../SignInForm';
import styles from './SignIn.module.scss';
import { useCheck } from '../../hooks/api/useCheck';
import { useConfirm } from '../../hooks/api/useConfirm';
import { QueryParamsContext } from '../../providers/QueryParams';
import { SessionContext } from '../../providers/Session';
import { redirect } from '../SignInForm/SignInForm.utils';
import { ProfileForm } from '../ProfileForm';
import { useProfileUpdate } from '../../hooks/api/useProfileUpdate';
import classnames from 'classnames';
import {
  AdditionalPhoneNumber,
  ConfirmResponseData,
  Inputs,
  ProfileUpdateRequestData,
} from '../../types';
import Loader from '../../ui/Loader';
import { useConfirmMfa } from '../../hooks/api/useConfirmMfa';
import { useInitiateMfa } from '../../hooks/api/useInitiateMfa';
import { routes } from '../../constants/routes';

const DAYS_THRESHOLD = 8;

const isDateOlderThan = (date: string) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - DAYS_THRESHOLD);

  const givenDate = new Date(date);

  return givenDate < currentDate;
};

export const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const session = useContext(SessionContext);
  const queryParams = useContext(QueryParamsContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isRedirectLoading, setIsRedirectLoading] = useState(false);

  const [mfaToken, setMfaToken] = useState('');
  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [remember, setRemember] = useState(false);

  const [step, setStep] = useState(0);
  const [confirmationResponse, setConfirmationResponse] =
    useState<ConfirmResponseData>({ access_token: '', just_signed_up: false });

  const checkEmail = useCheck();
  const confirmCode = useConfirm();

  const confirmMfa = useConfirmMfa();
  const initiateMfa = useInitiateMfa();

  const profileUpdate = useProfileUpdate();

  const performRedirect = () => {
    redirect({
      url: queryParams['redirectUrl'],
      sessionToken: session,
      accessToken: confirmationResponse.access_token,
      justSignedUp: confirmationResponse.just_signed_up,
      remember,
    });
  };

  const handleCheckEmail = (email: string) =>
    checkEmail({
      email,
    });

  const handleMfa = async (token: string) => {
    const response = await initiateMfa({
      mfa_token: token,
    });
    setMfaToken(response.mfa_token);
    return response;
  };

  const removeEmptyFields = (data: ProfileUpdateRequestData) => {
    Object.keys(data).forEach((key) => {
      if (
        data[key] === '' ||
        data[key] == null ||
        (typeof data[key] === 'object' && Object.keys(data[key]).length === 0)
      ) {
        delete data[key];
      } else if (typeof data[key] === 'object') {
        removeEmptyFields(data[key]);
      }
    });
  };

  const handleProfileUpdateSubmit = async (data: ProfileUpdateRequestData) => {
    setIsLoading(true);

    const prepareFormData = (
      data: ProfileUpdateRequestData
    ): ProfileUpdateRequestData => {
      let formData = JSON.parse(JSON.stringify(data));

      removeEmptyFields(formData);

      delete formData.services?.brochures_countList;

      formData.personal.email = email;

      if (formData.company_info?.additional_phone_numbers) {
        formData.company_info.additional_phone_numbers =
          formData.company_info.additional_phone_numbers.filter(
            (item: AdditionalPhoneNumber) =>
              item.phone_number !== undefined && item.phone_number !== ''
          );
      }

      return formData;
    };

    const updateProfile = async (formData: ProfileUpdateRequestData) => {
      const customHeaders = {
        'X-AppClose-Access-Token': confirmationResponse.access_token,
      };
      try {
        await profileUpdate(formData, customHeaders);
        setIsRedirectLoading(true);
        performRedirect();
      } catch (error) {
        throw error;
      }
    };

    try {
      const formData = prepareFormData(data);
      await updateProfile(formData);
    } finally {
      setIsLoading(false);
      setIsRedirectLoading(false);
    }
  };

  const handleSkip = () => {
    setIsRedirectLoading(true);
    performRedirect();
  };

  const onSubmit = async (data: Inputs) => {
    setIsLoading(true);
    setEmail(data.email);
    setRemember(data.remember_me);

    try {
      if (step === 0) {
        await handleCheckEmail(data.email);
        setStep(1);
      } else if (step === 1) {
        try {
          const response = await confirmCode({
            email,
            verification_code: data.code,
          });

          setConfirmationResponse(response);

          if (
            response.just_signed_up === false &&
            response.professional === null &&
            isDateOlderThan(response.created_at) &&
            response.firstname !== null
          ) {
            navigate(routes.forbidden);

            return;
          }

          if (response.just_signed_up || response.firstname === null || response.professional === null) {
            setStep(2);
            return;
          } else {
            setIsRedirectLoading(true);

            redirect({
              url: queryParams['redirectUrl'],
              sessionToken: session,
              accessToken: response.access_token,
              justSignedUp: response.just_signed_up,
              remember: data.remember_me,
            });
          }
        } catch (error) {
          if (error.errorCode === 401008) {
            setMfaToken(error.mfa_token);
            setMaskedPhoneNumber(error.masked_phone_number);
            await handleMfa(error.mfa_token);

            setStep(3);
          } else {
            throw error;
          }
        }
      } else if (step === 3) {
        try {
          const mfaResponse = await confirmMfa({
            mfa_token: mfaToken,
            mfa_code: data.phone_code,
          });

          setIsRedirectLoading(true);
          redirect({
            url: queryParams['redirectUrl'],
            sessionToken: session,
            accessToken: mfaResponse?.access_token,
            justSignedUp: mfaResponse?.just_signed_up,
            remember,
          });
        } catch (error) {
          throw error;
        }
      }
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const renderForm = () => {
    switch (step) {
      case 0:
      case 1:
        return (
          <SignInForm
            loading={isLoading}
            email={email}
            step={step}
            onSubmit={onSubmit}
            onResendEmail={() => handleCheckEmail(email)}
          />
        );
      case 2:
        return (
          <ProfileForm
            onSubmit={handleProfileUpdateSubmit}
            loading={isLoading}
            onSkip={handleSkip}
          />
        );
      case 3:
        return (
          <SignInForm
            loading={isLoading}
            email={email}
            step={step}
            phone={maskedPhoneNumber}
            onSubmit={onSubmit}
            onResendSms={async () => await handleMfa(mfaToken)}
          />
        );
      default:
        return null;
    }
  };

  if (isRedirectLoading) {
    return <Loader />;
  }

  return (
    <div
      className={classnames({
        [styles.container]: step < 4,
      })}
    >
      <Column gap={40}>
        <Header />
        {renderForm()}
      </Column>
    </div>
  );
};
