import classNames from 'classnames';
import styles from './Block.module.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

export const Block: React.FC<Props> = ({ children, className }) => {
  return <div className={classNames(styles.block, className)}>{children}</div>;
};
