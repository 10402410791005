import { useEffect, useState } from 'react';
import { Button } from '../../../ui/buttons';

import styles from './ResendCode.module.scss';
import { Flex } from '../../../ui/Flex';

type ResendCodePropsType = {
  className?: string;
  timeout?: number;
  onResend: () => void;
};

export const ResendCode: React.FC<ResendCodePropsType> = ({
  timeout = 60000,
  className,
  onResend,
}: ResendCodePropsType) => {
  const seconds = timeout / 1000;
  const [timer, setTimer] = useState(seconds);
  let interval: number;

  const startTimer = () => {
    return window.setInterval(() => {
      setTimer((prev) => {
        if (prev === 0) {
          clearInterval(interval);

          return prev;
        }

        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    interval = startTimer();

    return () => clearInterval(interval);
  }, []);

  const handleResend = () => {
    onResend();

    setTimer(seconds);
    startTimer();
  };

  return (
    <Flex justify="center" className={className}>
      {timer === 0 && (
        <Button onClick={handleResend} skin="link">
          Resend verification code
        </Button>
      )}
      {timer > 0 && (
        <p className={styles.description}>
          You can resend the code in {timer} seconds
        </p>
      )}
    </Flex>
  );
};
