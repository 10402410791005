export const redirect = ({
  url,
  sessionToken,
  accessToken,
  justSignedUp = false,
  remember = false,
}: {
  url: string;
  sessionToken: string;
  accessToken: string;
  remember: boolean;
  justSignedUp?: boolean;
}) => {
  const redirect = new URL(url);
  redirect.searchParams.set('access_token', accessToken);
  redirect.searchParams.set('session_token', sessionToken);
  redirect.searchParams.set('just_signed_up', justSignedUp.toString());
  redirect.searchParams.set('remember', remember.toString());

  window.location.href = redirect.toString();
};
