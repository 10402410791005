import { useMemo } from 'react';

export default function useRetainer() {
  const retainerOptions = useMemo(
    () => [
      {
        label: 'No retainer',
        value: 'no-retainer',
      },
      {
        label: 'Retainer (Sometimes)',
        value: 'retainer-sometimes',
      },
      {
        label: 'Retainer (Always)',
        value: 'retainer-always',
      },
    ],
    []
  );

  const getRetainerLabel = (retainer: string) => {
    const option = retainerOptions.find((option) => option.value === retainer);

    return option?.label || '';
  };

  return {
    retainerOptions,
    getRetainerLabel,
  };
}
