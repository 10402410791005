import { SelectFormField, SelectFormFieldPropsType } from '../Select';
import { useFormContext } from '../../common/Form';
import { InputFormField } from '../Input';
import { useMemo } from 'react';
import { Flex } from '../../../ui/Flex';

export type BrochuresCountFormFieldPropsType = {
  name?: string;
  label?: string;
  additionalLabel?: string;
} & Omit<SelectFormFieldPropsType<string>, 'options' | 'name' | 'label'>;

export const BrochuresCounts = [
  10, 50, 100, 250, 500, 700, 1000, 1001,
] as const;

type FormValues = {
  services?: {
    brochures_count?: number;
    brochures_countList?: number;
  };
};

export default function BrochuresCountFormField({
  name = 'brochures_count',
  label = 'Number of brochures',
  additionalLabel = 'Specify number',
  placeholder = 'Select count...',
  required,
  ...rest
}: BrochuresCountFormFieldPropsType) {
  const { values, setFieldValue } = useFormContext<FormValues>();

  const countsOptions = useMemo(
    () =>
      BrochuresCounts.map((count) =>
        count === 1001
          ? {
              label: 'more',
              value: count,
            }
          : {
              label: count,
              value: count,
            }
      ),
    []
  );
  const listName = name + 'List';
  const isMore = values.services?.brochures_countList >= 1001;

  return (
    <Flex gap={[24]}>
      <SelectFormField
        name={listName}
        label={label}
        placeholder={placeholder}
        options={countsOptions}
        required={required}
        onChange={(value) => {
          setFieldValue(name, Number(value));
        }}
        {...rest}
      />
      <InputFormField
        name={name}
        min={1}
        label={additionalLabel}
        type={!isMore ? 'hidden' : 'number'}
      />
    </Flex>
  );
}
