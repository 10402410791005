.label {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #989FA3;
}

.icon {
  margin-left: 8px;
}
