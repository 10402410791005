import { useCallback } from 'react';

import styles from './MultiSelectHeader.module.scss';
import { MultiSelectHeaderType } from './MultiSelectHeader.types';
import { Button } from '../../../buttons';

export default function MultiSelectHeader({
  multipleOptionsTitle,
  multipleOkButton,
  isSelected = false,
  multipleClearButton,
  onOkClick,
  onClearClick,
}: MultiSelectHeaderType) {
  const handleOkClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onOkClick();
    },
    [onOkClick]
  );

  const handleClearClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onClearClick();
    },
    [onClearClick]
  );

  return (
    <div className={styles.default}>
      <div className={styles.title}>{multipleOptionsTitle}</div>
      <div className={styles.actionsContainer}>
        {isSelected && (
          <Button
            skin="shell"
            className={styles.actionClear}
            onClick={handleClearClick}
          >
            <div>{multipleClearButton}</div>
          </Button>
        )}
        <Button skin="shell" onClick={handleOkClick}>
          <div>{multipleOkButton}</div>
        </Button>
      </div>
    </div>
  );
}
