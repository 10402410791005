import { FormGroup } from '../../../common/Form';
import { Block } from '../../../../ui/Block';
import { AppclosePhoneField } from '../../../fields/AppclosePhoneField';
import useAppclosePhoneTypes from '../../../../hooks/useAppclosePhoneTypes';
import FieldsetFormArray from '../../../fields/FieldsetFormArray';
import { SelectFormField } from '../../../fields/Select';
import { Fieldset } from '../../../common/Fieldset';
import {
  AppclosePhoneTypes,
  ProfileUpdateRequestData,
} from '../../../../types';

type PhoneNumbersInfoFieldsetPropsType = {
  values: ProfileUpdateRequestData;
};

export function PhoneNumbersInfoFieldset({
  values,
}: PhoneNumbersInfoFieldsetPropsType) {
  const { phoneTypesOptions } = useAppclosePhoneTypes();

  return (
    <Block>
      <Fieldset title="Phone numbers">
        <FormGroup>
          <FormGroup>
            <SelectFormField
              name="company_info.phone_number_type"
              label="Phone number type"
              options={phoneTypesOptions}
            />
          </FormGroup>
          <FormGroup>
            <AppclosePhoneField
              codeName="company_info.phone_number_country"
              codeValue={values.company_info.phone_number_country}
              phoneName="company_info.phone_number"
              phoneLabel="Phone number"
              disabled
              required
            />
          </FormGroup>
        </FormGroup>

        <FieldsetFormArray
          name="company_info.additional_phone_numbers"
          addButtonLabel="Add another phone number"
          limit={5}
          initialData={{
            type: AppclosePhoneTypes.WORK,
            phone_number: '',
            phone_number_country: 'US',
          }}
        >
          {({ resolveName, values, index }) => (
            <FormGroup>
              <FormGroup>
                <SelectFormField
                  name={resolveName('type')}
                  label="Phone number type"
                  options={phoneTypesOptions}
                />
              </FormGroup>
              <FormGroup>
                <AppclosePhoneField
                  codeName={resolveName('phone_number_country')}
                  codeValue={values[index]?.phone_number_country}
                  phoneName={resolveName('phone_number')}
                  phoneLabel="Phone number"
                  disabled
                />
              </FormGroup>
            </FormGroup>
          )}
        </FieldsetFormArray>
      </Fieldset>
    </Block>
  );
}
