
$offset: 12px;

.tooltipTarget {
  display: contents;
}

.tooltip {
  z-index: 1;
  max-width: 50%;
  padding: 8px $offset;
  word-break: break-word;
  background-color: var(--color-white);
  border-radius: 10px;
  filter: drop-shadow(0 5px 12px rgba(0, 0, 0, 0.05));

  .arrow {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    line-height: 1;
  }

  &[data-placement='top'] {
    .arrow {
      transform: rotate3d(1, 0, 0, 180deg) translate3d(0, -100%, 0);
    }
  }

  &[data-placement='top-start'] {
    .arrow {
      right: auto;
      left: 12px;
      transform: rotate3d(1, 0, 0, 180deg) translate3d(0, -100%, 0);
    }
  }

  &[data-placement='top-end'] {
    .arrow {
      right: $offset;
      left: auto;
      transform: rotate3d(1, 0, 0, 180deg) translate3d(0, -100%, 0);
    }
  }

  &[data-placement*='right'] {
    .arrow {
      top: 0;
      right: auto;
      left: calc($offset / 2) * -1;
      margin: auto 0;
      transform: rotate3d(0, 0, 1, -90deg) translate3d(0, -100%, 0);
    }
  }

  &[data-placement*='left'] {
    .arrow {
      top: 0;
      right: calc($offset / 2) * -1;
      left: auto;
      margin: auto 0;
      transform: rotate3d(0, 0, 1, 90deg) translate3d(0, -100%, 0);
    }
  }

  &[data-placement='bottom'] {
    .arrow {
      top: 0;
      bottom: auto;
      transform: translate3d(0, -100%, 0);
    }
  }

  &[data-placement='bottom-start'] {
    .arrow {
      top: 0;
      right: auto;
      bottom: auto;
      left: $offset;
      transform: translate3d(0, -100%, 0);
    }
  }

  &[data-placement='bottom-end'] {
    .arrow {
      top: 0;
      right: $offset;
      bottom: auto;
      left: auto;
      transform: translate3d(0, -100%, 0);
    }
  }
}
