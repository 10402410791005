import { useContext } from 'react';
import { FetchContext } from '../../providers/Fetch';
import { ConfirmMfaRequestData, ConfirmMfaResponseData } from '../../types';
import { API_URLS } from '../../constants/apiConstants';

export const useConfirmMfa = () => {
  const fetch = useContext(FetchContext);

  return async (
    data: ConfirmMfaRequestData
  ): Promise<ConfirmMfaResponseData> => {
    try {
      const response = await fetch({
        url: API_URLS.CONFIRM_MFA,
        params: {
          options: {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-AppClose-MFA-Token': data.mfa_token,
              'X-AppClose-MFA-Code': data.mfa_code,
            },
          },
        },
        errors: {
          401007: 'Invalid code',
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };
};
