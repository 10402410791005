import { useMemo } from 'react';

type YearSelectFormFieldOptionsType = {
  value: string;
  label: string;
}[];

const LICENSE_MIN_YEAR = 1929;

export function useOptions() {
  const currentYear = new Date().getFullYear();

  const years: YearSelectFormFieldOptionsType = useMemo(() => {
    return Array.from(
      { length: currentYear - LICENSE_MIN_YEAR + 1 },
      (_, index) => ({
        value: (currentYear - index).toString(),
        label: (currentYear - index).toString(),
      })
    );
  }, [currentYear]);

  return {
    options: years,
  };
}
