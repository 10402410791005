import { FieldProps } from 'formik';

import { FiledPropsType } from '../../../../hoc/field';
import { SelectPropsType, SelectType } from '../../../../../ui/Select';

export type SelectFormFieldWrapperPropsType = FieldProps &
  FiledPropsType<SelectPropsType>;

export type SelectFormFieldPropsType<ValueType extends SelectType> = Omit<
  FiledPropsType<SelectPropsType<ValueType>>,
  'onChange' | 'value' | 'defaultOptions'
> & {
  onChange?: (value: ValueType) => void;
};
