import { FormGroup } from '../../../common/Form';
import { Fieldset } from '../../../common/Fieldset';
import {
  InputFormField,
  UpperFirstInputFormField,
} from '../../../fields/Input';
import { Block } from '../../../../ui/Block';
import { Column } from '../../../../ui/Column';
import { USAStateSelectFormField } from '../../../fields/Select';
import { YearSelectFormField } from '../../../fields/YearSelectFormField ';

export function LicenseInfoFieldset() {
  return (
    <Block>
      <Fieldset title="license & occupation">
        <FormGroup>
          <Column gap={24}>
            <USAStateSelectFormField
              name="license.licensed_in"
              label="Licensed in States"
              placeholder="Licensed in States"
              isMulti
            />
            <InputFormField
              name="license.license_number"
              label="License number"
              placeholder="License number"
            />
            <YearSelectFormField
              name="license.licensed_since"
              label="Licensed since"
              placeholder="Licensed since"
            />
            <UpperFirstInputFormField
              name="license.occupation"
              required
              label="Occupation"
              placeholder="Occupation"
            />
          </Column>
        </FormGroup>
      </Fieldset>
    </Block>
  );
}
