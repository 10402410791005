import classnames from 'classnames';

import Loader from '../../../Loader';
import { FormArrayField } from '../../';
import { EnhancedFormArrayFieldPropsType } from './EnhancedFormArrayField.types';
import DeletableGroup from './components/DeletableGroup/DeletableGroup';
import styles from './EnhancedFormArrayField.module.scss';
import MovableGroup from './components/MovableGroup';
import { AddButton } from '../../../buttons/components/AddButton';

export default function EnhancedFormArrayField({
  isLoading,
  addButtonLabel,
  allowRemoveLast = true,
  allowAddNew = true,
  initialData,
  name,
  limit = Infinity,
  footer,
  deleteButtonPosition = 'center',
  className,
  sortable = false,
  deletable = true,
  disabled,
  children,
}: EnhancedFormArrayFieldPropsType) {
  return (
    <FormArrayField name={name}>
      {({ push, remove, move, values, resolveName, name }) => {
        return (
          <div className={classnames(styles.formArrayField, className)}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {values.map((value, i) => (
                  <MovableGroup
                    enabled={sortable && values.length > 1}
                    index={i}
                    length={values.length}
                    onClick={({ to }) => move(i, to)}
                    key={i}
                  >
                    <DeletableGroup
                      enabled={
                        deletable && (values.length > 1 || allowRemoveLast)
                      }
                      position={deleteButtonPosition}
                      onDelete={() => remove(i)}
                    >
                      {children({
                        index: i,
                        value,
                        values,
                        resolveName: (fieldName?: string) =>
                          resolveName(i, fieldName),
                      })}
                    </DeletableGroup>
                  </MovableGroup>
                ))}
                {footer}
                {allowAddNew && (
                  <AddButton
                    className={styles.addButton}
                    onClick={() => push(initialData)}
                    size={28}
                    label={addButtonLabel}
                    skin="success"
                    disabled={disabled || values?.length === limit}
                  />
                )}
                {/* <div id={name}>
                  <FieldError name={name} />
                </div> */}
              </>
            )}
          </div>
        );
      }}
    </FormArrayField>
  );
}
