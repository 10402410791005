import * as Yup from 'yup';

export const getNameValidationSchema = (
  nameType: 'First' | 'Last' | 'Entity',
  max = 30,
  min = 2
) => {
  let schema = Yup.string()
    .nullable()
    .max(max, () => {
      switch (nameType) {
        case 'First':
          return 'First name should be at max 100 characters';
        case 'Last':
          return 'Last name should be at max 100 characters';
        case 'Entity':
          'Entity name should be at max 100 characters';
      }
    })
    .min(min, () => {
      switch (nameType) {
        case 'First':
          return 'First name should be at min 2 characters';
        case 'Last':
          return 'Last name should be at min 2 characters';
      }
    });

  switch (nameType) {
    case 'First':
    case 'Last':
      return schema.matches(/^[A-Z]/, 'First letter should be capital');

    default:
      return schema;
  }
};

export const PROFILE_FIRST_NAME_VALIDATION_SCHEMA = getNameValidationSchema(
  'First'
).required('Please enter a first name');

export const PROFILE_LAST_NAME_VALIDATION_SCHEMA = getNameValidationSchema(
  'Last'
).required('Please enter a last name');
