import { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import useCountries from '../../../hooks/useCountries';
import { SelectFormField, SelectFormFieldPropsType } from '../Select';

export type CountrySelectFormFieldPropsType = {
  name?: string;
  label?: string;
} & Omit<SelectFormFieldPropsType<string>, 'options' | 'name' | 'label'>;

export default function CountryPhoneCodeSelectFormField({
  name = 'phoneCode',
  ...rest
}: CountrySelectFormFieldPropsType) {
  const { dialCodesOptions } = useCountries();
  const [options, setOptions] = useState(dialCodesOptions);

  const onSearchChange = useMemo(
    () =>
      debounce((search: string = '') => {
        if (!search) {
          setOptions(dialCodesOptions);

          return;
        }

        const filtered = dialCodesOptions.filter((option) => {
          return option.label.toLowerCase().includes(search.toLowerCase());
        });

        setOptions(filtered);
      }, 500),
    [setOptions, dialCodesOptions]
  );
  const handleOnBlur = useCallback(() => {
    setOptions(dialCodesOptions);
  }, [dialCodesOptions]);

  return (
    <SelectFormField
      name={name}
      options={options}
      {...rest}
      withSearch
      clearable={false}
      onSearchChange={onSearchChange}
      onBlur={handleOnBlur}
    />
  );
}
