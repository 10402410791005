import { useContext } from 'react';
import { FetchContext } from '../../providers/Fetch';
import { ProfileUpdateRequestData } from '../../types';
import { API_URLS } from '../../constants/apiConstants';

export const useProfileUpdate = () => {
  const fetch = useContext(FetchContext);

  return (
    data: ProfileUpdateRequestData,
    headers: Record<string, string>
  ): Promise<void> =>
    fetch({
      url: API_URLS.UPDATE_PROFILE,
      params: {
        data,
        options: {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            ...headers,
          },
        },
      },
      errors: {
        400: 'Invalid code',
      },
    });
};
