import { createContext } from 'react';

import { FormContextType } from './Form.types';

export const FormContext = createContext<FormContextType>({
  submissionError: null,
  processes: [],
  addProcess() {},
  setSubmissionError() {},
});
