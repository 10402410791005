import { useMemo } from 'react';
import data from './countries.json';

type Country = {
  code: string;
  currency_code: string;
  name: string;
  dial_code: string;
};

export default function useCountries() {
  const countries: Country[] = useMemo(() => data, []);
  const countryCodes = useMemo(
    () => countries.map((country) => country.code),
    [countries]
  );
  const dialCodes = useMemo(
    () => countries.map((country) => country.dial_code),
    [countries]
  );
  const dialCodesOptions = useMemo(
    () =>
      countries.map((country) => ({
        label: `+${country.dial_code}`,
        value: country.code,
      })),
    [countries]
  );
  const countriesOptions = useMemo(
    () =>
      countries.map((country) => ({
        label: country.name,
        value: country.code,
      })),
    [countries]
  );

  const getPhoneCodeByCounryCode = (countryCode: string) => {
    const country = countries.find((country) => country.code === countryCode);

    return country?.dial_code || '';
  };

  const getCountryNameByCode = (countryCode: string) => {
    const country = countries.find((country) => country.code === countryCode);

    return country?.name || '';
  };

  return {
    countries,
    countryCodes,
    dialCodes,
    dialCodesOptions,
    countriesOptions,
    getPhoneCodeByCounryCode,
    getCountryNameByCode,
  };
}
