import classnames from 'classnames';
import PlusSmallIcon from '../../../../assets/icons/plus-small.svg';

import { AddNewPropsType } from './AddNew.types';
import styles from './AddNew.module.scss';
import { Button } from '../../../buttons';

export default function AddNew({
  label,
  className,
  onAdd,
  disabled,
}: AddNewPropsType) {
  return (
    <Button
      skin="shell"
      className={classnames(styles.addNew, className)}
      onClick={onAdd}
      disabled={disabled}
    >
      <div className={styles.button}>
        <PlusSmallIcon fill="#fff" />
      </div>
      <p className={styles.label}>{label}</p>
    </Button>
  );
}
