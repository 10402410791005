import useRetainer from '../../../hooks/useRetainer';
import { SelectFormField, SelectFormFieldPropsType } from '../Select';

export type RetainerSelectFormFieldPropsType = {
  name?: string;
  label?: string;
} & Omit<SelectFormFieldPropsType<string>, 'options' | 'name' | 'label'>;

export default function RetainerSelectFormField({
  name = 'retainer',
  label = 'Retainer',
  placeholder = 'Select retainer...',
  ...rest
}: RetainerSelectFormFieldPropsType) {
  const { retainerOptions } = useRetainer();

  return (
    <SelectFormField
      name={name}
      label={label}
      placeholder={placeholder}
      options={retainerOptions}
      {...rest}
    />
  );
}
