import React, { useEffect, useRef, useImperativeHandle } from 'react';
import classnames from 'classnames';

import styles from './TextArea.module.scss';
import { TextAreaPropsType } from './TextArea.types';

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaPropsType>(
  (
    {
      className,
      disabled = false,
      expandable = true,
      hasError = false,
      maxLength = 400,
      minLength,
      name,
      autoFocus,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onKeyPress,
      placeholder,
      rows = 1,
      value,
    },
    forwardedRef
  ) => {
    const ref = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(forwardedRef, () => ref.current as HTMLTextAreaElement);

    useEffect(() => {
      if (!ref.current) {
        return;
      }

      const target = ref.current;

      const observer = new IntersectionObserver(([e]) => {
        if (e.intersectionRatio > 0) {
          target.style.height = 'inherit';
          target.style.height = target.scrollHeight + 'px';
        }
      });

      observer.observe(target);

      return () => {
        observer.unobserve(target);
      };
    }, []);

    useEffect(() => {
      if (expandable && ref.current) {
        ref.current.style.height = 'inherit';
        ref.current.style.height = ref.current.scrollHeight + 'px';
      }
    }, [value, ref, expandable]);

    return (
      <textarea
        ref={ref}
        className={classnames(styles.textArea, className)}
        disabled={disabled}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        autoFocus={autoFocus}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        rows={rows}
        value={value}
      />
    );
  }
);

export default TextArea;
