@import 'scss/variables';

.select {
  position: relative;
  display: block;
  width: 100%;
}

.valueContainer {
  padding-right: 16px;
}

.hide {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.selectValue {
  overflow: hidden;
  font-size: 1.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: no-repeat bottom;
  background-color: transparent;
  background-image: linear-gradient(0deg, #a4e750 1px, rgba(0, 150, 136, 0) 0), linear-gradient(0deg, #e8ecec 1px, transparent 0) !important;
  background-size: 0 100%, 100% 100%;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  transition: background 0s ease-out;
}

.selectValue:focus {
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
}

.selectValue:empty::before {
  overflow: hidden;
  color: var(--color-text-gray);
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  content: attr(data-placeholder);
}

.searchInput {
  composes: valueContainer;
}

.arrowIcon {
  position: absolute;
  top: calc(50% - 8px);
  right: 0;
  display: block;
  width: 16px;
  height: 16px;
  transition: transform ease 0.5s;
  pointer-events: none;
}

.isOpen {
  transform: scaleY(-1);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.disabled .selectValue,
.disabled .icon {
  pointer-events: none;
}

.menu {
  z-index: 1;
  padding: 24px 0;
  overflow: hidden;
  background-color: var(--color-white);
  border-radius: 12px;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.15);
  user-select: none;
}

.options {
  max-height: 280px;
  padding: 0 $menuXPadding;
  overflow-y: auto;
}

.addNew {
  width: calc(100% + #{$menuXPadding} * 2);
  margin: 0 ($menuXPadding * -1);
  padding: 15px ($optionXPadding + $menuXPadding);
}

.hasAddNew.menu {
  padding-bottom: 0;
}

.noOptionsTitle {
  margin: 0;
  padding: 0 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;
}

