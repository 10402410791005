import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import unionWith from 'lodash/unionWith';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import throttle from 'lodash/throttle';

import { Dropdown, DropdownRefType, DropdownToggleSource } from '../Dropdown';

import MultiSelectHeader from './components/MultiSelectHeader';
import OptionsHeader from './components/OptionsHeader';
import Option from './components/Option';
import OptionsLoading from './components/OptionsLoading';

import styles from './Select.module.scss';
import { OptionType, SelectPropsType, SelectType } from './Select.types';
import AddNew from './components/AddNew';
import {
  defaultOptionLabelResolver,
  defaultOptionValueResolver,
  defaultSelectedOptionsResolver,
  defaultValueResolver,
} from './Select.utils';
import { Input } from '../Input';
import ArrowDownIcon from '../../assets/icons/arrow-down.svg';

const MIN_SELECT_MENU_WIDTH = 240;

export default function Select<TValue extends SelectType>({
  name,
  value,
  iconClassName,
  optionClassName,
  labelClassName,
  valueResolver = defaultValueResolver,
  optionLabelResolver = defaultOptionLabelResolver,
  optionValueResolver = defaultOptionValueResolver,
  selectedOptionsResolver = defaultSelectedOptionsResolver,
  options,
  defaultOptions = [],
  className,
  disabled = false,
  clearable = true,
  hasError = false,
  isMulti,
  withSearch = false,
  onSearchChange,
  isLoading = false,
  optionsLoader = <OptionsLoading />,
  onLoadOptions,
  onChange,
  onBlur,
  onFocus,
  placeholder = 'Select...',
  multipleOptionsTitle = 'Select multiple options',
  multipleOkButton = 'Ok',
  multipleClearButton = 'Clear',
  searchOptionsTitle = 'Select an option or type to search',
  optionsTitle = 'Select an option',
  noOptionsTitle = 'No available options',
  optionsClearButton = 'Clear',
  addLabel = 'Create new',
  onAdd,
  onAddDisabled,
  defaultOpen = false,
}: SelectPropsType<TValue>): React.ReactElement {
  if (
    isMulti &&
    value !== null &&
    value !== undefined &&
    !Array.isArray(value)
  ) {
    throw Error('For multiple Select the value should be an array');
  }

  const selectRef = useRef<HTMLInputElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<DropdownRefType>(null);
  const isOptionsLoadingRef = useRef<boolean>(false);
  const hasMoreOptionsRef = useRef<boolean>(true);

  useEffect(() => {
    if (dropdownRef.current && defaultOpen) {
      dropdownRef.current.setIsOpen(true);
    }
  }, [dropdownRef]); // eslint-disable-line

  const [searchValue, setSearchValue] = useState<string>('');
  const [highlightedValue, setHighlightedValue] = useState<TValue | null>(null);
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);

  const values = useMemo(() => {
    if (value === undefined || (isMulti && value === null)) {
      return [];
    }

    return Array.isArray(value) ? value : [value];
  }, [value, isMulti]);

  const onSetSearch = useCallback(
    (value: string) => {
      hasMoreOptionsRef.current = true;
      setSearchValue(value);
      onSearchChange && onSearchChange(value);
    },
    [onSearchChange]
  );

  const normalizedValue = useMemo(
    () => values.map(valueResolver),
    [values, valueResolver]
  );

  const getOptionValue = useCallback(
    (option: OptionType<TValue>) => valueResolver(optionValueResolver(option)),
    [optionValueResolver, valueResolver]
  );

  const getUnionOptions = useCallback(
    (
      options: OptionType<TValue>[] | undefined,
      otherOptions: OptionType<TValue>[] | undefined
    ) =>
      unionWith(
        options,
        otherOptions,
        (a, b) => getOptionValue(a) === getOptionValue(b)
      ),
    [getOptionValue]
  );

  const selectOptions = useMemo(
    () =>
      getUnionOptions(options, defaultOptions).filter(
        (option) => !isNil(optionLabelResolver(option))
      ),
    [defaultOptions, getUnionOptions, options, optionLabelResolver]
  );

  const selectedOptions = useMemo(() => {
    const optionsWithValue = getUnionOptions(selectOptions, values);

    return optionsWithValue.filter(
      (option) => normalizedValue.indexOf(getOptionValue(option)) !== -1
    );
  }, [getOptionValue, getUnionOptions, normalizedValue, selectOptions, values]);

  const selectedLabels = useMemo(() => {
    const label = selectedOptionsResolver(selectedOptions, isMulti);

    return label !== undefined
      ? label
      : defaultSelectedOptionsResolver(selectedOptions);
  }, [isMulti, selectedOptions, selectedOptionsResolver]);

  const closeMenu = useCallback(() => {
    dropdownRef.current?.setIsOpen(false);
  }, [dropdownRef]);

  const handleOnFocus = useCallback(
    (e) => {
      if (!disabled) {
        onFocus && onFocus(e);
      }
    },
    [disabled, onFocus]
  );

  const handleOnBlur = useCallback(
    (e) => {
      if (!disabled) {
        onBlur && onBlur(e);
      }
    },
    [disabled, onBlur]
  );

  const handleOnDropDownToggle = useCallback(
    (isOpen: boolean, source: DropdownToggleSource) => {
      if (isOpen && searchRef.current) {
        searchRef.current.focus();
      } else if (!isOpen) {
        selectRef.current?.focus();
        if (source === 'outside') {
          selectRef.current?.blur();
        }
      }
    },
    []
  );

  const handleOnOptionSelect = useCallback(
    (optionValue: TValue) => {
      if (!isMulti) {
        onChange(optionValue);
        closeMenu();
      } else {
        if (normalizedValue.indexOf(valueResolver(optionValue)) === -1) {
          onChange([...values, optionValue]);
        } else {
          const newValues = values.filter(
            (oldValue) => valueResolver(oldValue) !== valueResolver(optionValue)
          );

          onChange(newValues);
        }
      }

      onSetSearch('');
    },
    [
      closeMenu,
      isMulti,
      values,
      normalizedValue,
      onChange,
      valueResolver,
      onSetSearch,
    ]
  );

  const handleOnKeyDown = useCallback(
    (e) => {
      if (disabled) {
        return;
      }

      const isMenuOpened = dropdownRef?.current?.isOpen;
      switch (e.key) {
        case 'Tab': {
          if (isMenuOpened) {
            closeMenu();
          } else if (document.activeElement === selectRef.current) {
            selectRef.current?.blur();
          }

          break;
        }
        case 'Escape': {
          if (isMenuOpened) {
            closeMenu();
            e.preventDefault();
            e.stopPropagation();
          }

          break;
        }
        case 'Enter': {
          if (isMenuOpened && highlightedValue) {
            handleOnOptionSelect(highlightedValue);
          }

          break;
        }
        case 'ArrowDown':
        case 'ArrowUp': {
          if (isMenuOpened && options) {
            const highlightedOption = options.find(
              (option) => optionValueResolver(option) === highlightedValue
            );
            const highlightedOptionIndex = highlightedOption
              ? options?.indexOf(highlightedOption)
              : -1;

            if (e.key === 'ArrowUp') {
              if (highlightedOptionIndex === -1) {
                setHighlightedValue(
                  optionValueResolver(options[options.length - 1])
                );
              } else if (highlightedOptionIndex <= 0) {
                setHighlightedValue(
                  optionValueResolver(options[options.length - 1])
                );
              } else {
                setHighlightedValue(
                  optionValueResolver(options[highlightedOptionIndex - 1])
                );
              }
            } else {
              if (highlightedOptionIndex === -1) {
                setHighlightedValue(optionValueResolver(options[0]));
              } else if (highlightedOptionIndex + 1 >= options.length) {
                setHighlightedValue(optionValueResolver(options[0]));
              } else {
                setHighlightedValue(
                  optionValueResolver(options[highlightedOptionIndex + 1])
                );
              }
            }

            e.preventDefault();
            e.stopPropagation();
          }

          break;
        }
        case ' ': {
          if (!isMenuOpened && selectRef.current === document.activeElement) {
            dropdownRef?.current?.setIsOpen(true);
            e.preventDefault();
            e.stopPropagation();
          } else if (isMenuOpened && !withSearch) {
            if (highlightedValue) {
              handleOnOptionSelect(highlightedValue);
            }

            e.preventDefault();
            e.stopPropagation();
          }
        }
      }
    },
    [
      disabled,
      closeMenu,
      handleOnOptionSelect,
      highlightedValue,
      optionValueResolver,
      options,
      withSearch,
    ]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleOnKeyDown, true);

    return () => {
      document.removeEventListener('keydown', handleOnKeyDown, true);
    };
  }, [handleOnKeyDown]);

  const handleOnInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSetSearch(e.target.value);

      if (clearable && !e.target.value) {
        onChange(undefined);
      }
    },
    [onSetSearch, clearable, onChange]
  );

  const handleOnClearSelect = useCallback(() => {
    if (clearable && !disabled) {
      onChange(undefined);
      onSetSearch('');
    }
  }, [clearable, disabled, onChange, onSetSearch]);

  const handleOnMultiClear = useCallback(() => {
    onSetSearch('');
    onChange([]);
  }, [onSetSearch, onChange]);

  const handleOnMultiOk = useCallback(() => {
    closeMenu();
  }, [closeMenu]);

  const handleDropdownConfig = useCallback(
    (data) => {
      const selectClientRect = data.state.rects.reference;

      const styles = {
        offset: 8,
        minWidth: (selectClientRect?.width || 0) + 64,
        maxWidth: Math.max(
          MIN_SELECT_MENU_WIDTH,
          2 * (selectClientRect?.width || 0) + 64
        ),
      };

      const stateStyles = { ...data.state.styles.popper };

      stateStyles.minWidth = styles.minWidth + 'px';
      stateStyles.maxWidth = styles.maxWidth + 'px';
      stateStyles.paddingTop = styles.offset + 'px';
      stateStyles.paddingBottom = styles.offset + 'px';

      if (!isEqual(data.state.styles.popper, stateStyles)) {
        data.state.styles.popper = stateStyles;
        data.instance.update();
      }

      return data.state;
    },
    // selectedOptions and options are required in dependency list to recalculate dropdown position correctly
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [withSearch, selectedOptions, options]
  );

  const getIsOptionSelected = useCallback(
    (option: OptionType<TValue>) => {
      return normalizedValue.indexOf(getOptionValue(option)) !== -1;
    },
    [getOptionValue, normalizedValue]
  );

  const handleOnAdd = useCallback(() => {
    if (onAdd) {
      setSearchValue('');

      onChange(undefined);
      onAdd();
    }
  }, [onAdd, setSearchValue, onChange]);

  const throttledOnOptionsLoad = useMemo(
    () =>
      throttle((callback: () => void) => {
        callback();
      }, 300),
    []
  );

  const handleOnOptionsLoad = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      if (onLoadOptions) {
        e.persist();

        throttledOnOptionsLoad(() => {
          const isLoading = isOptionsLoadingRef.current;
          const hasMoreOptions = hasMoreOptionsRef.current;

          const { scrollTop, offsetHeight, scrollHeight } =
            e.target as HTMLDivElement;

          if (
            !isLoading &&
            hasMoreOptions &&
            scrollTop + offsetHeight > scrollHeight / 2
          ) {
            isOptionsLoadingRef.current = true;
            setIsOptionsLoading(true);

            onLoadOptions?.()
              .then((result) => (hasMoreOptionsRef.current = result as boolean))
              .finally(() => {
                isOptionsLoadingRef.current = false;
                setIsOptionsLoading(false);
              });
          }
        });
      }
    },
    [throttledOnOptionsLoad, onLoadOptions, isOptionsLoadingRef]
  );

  return (
    <Dropdown
      position="bottom"
      className={classnames(styles.select, className, {
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
      dropdownConfig={handleDropdownConfig}
      onDropdownToggle={handleOnDropDownToggle}
      ref={dropdownRef}
      toggleComponent={({ isDropdownOpen }) => {
        const showContainer = !isDropdownOpen || !withSearch;

        return (
          <>
            <div
              id={name}
              className={classnames(styles.selectValue, {
                [styles.hide]: !showContainer,
              })}
              data-placeholder={placeholder || '\xa0'}
              onBlur={!isDropdownOpen ? handleOnBlur : undefined}
              onFocus={!isDropdownOpen ? handleOnFocus : undefined}
              ref={selectRef}
              tabIndex={0}
            >
              {selectedLabels}
            </div>
            {withSearch && (
              <Input
                ref={searchRef}
                autoComplete="off"
                className={classnames(styles.searchInput, {
                  [styles.hide]: showContainer,
                })}
                hasError={hasError}
                onChange={handleOnInputChange}
                placeholder={placeholder}
                value={searchValue || ''}
                tabIndex={-1}
              />
            )}
            <img
              src={ArrowDownIcon}
              className={classnames(
                styles.arrowIcon,
                iconClassName,
                isDropdownOpen && styles.isOpen
              )}
              alt="ArrowDownIcon"
            />
          </>
        );
      }}
      content={
        <div
          className={classnames(styles.menu, {
            [styles.hasAddNew]: onAdd,
          })}
        >
          {isMulti && (
            <MultiSelectHeader
              isSelected={normalizedValue.length > 0}
              multipleClearButton={multipleClearButton}
              multipleOkButton={multipleOkButton}
              multipleOptionsTitle={multipleOptionsTitle}
              onClearClick={handleOnMultiClear}
              onOkClick={handleOnMultiOk}
            />
          )}
          {!isMulti && clearable && (
            <OptionsHeader
              optionsTitle={withSearch ? searchOptionsTitle : optionsTitle}
              hasSearchValue={!!searchValue || !!selectedOptions.length}
              optionsClearButton={optionsClearButton}
              onClearClick={handleOnClearSelect}
            />
          )}
          <div className={styles.options} onScrollCapture={handleOnOptionsLoad}>
            {!isLoading &&
              selectOptions.map((option, key) => (
                <Option<TValue>
                  key={getOptionValue(option) ?? key}
                  value={optionValueResolver(option)}
                  label={optionLabelResolver(option)}
                  highlighted={highlightedValue === optionValueResolver(option)}
                  isSelected={getIsOptionSelected(option)}
                  isMulti={isMulti}
                  theme={{
                    option: optionClassName || styles.option,
                    label: labelClassName || styles.label,
                  }}
                  onHighlight={setHighlightedValue}
                  onSelect={handleOnOptionSelect}
                />
              ))}
            {isLoading || isOptionsLoading ? (
              optionsLoader
            ) : !selectOptions?.length ? (
              <p className={styles.noOptionsTitle}>{noOptionsTitle}</p>
            ) : null}
          </div>
          {onAdd && (
            <AddNew
              label={addLabel}
              className={styles.addNew}
              onAdd={handleOnAdd}
              disabled={onAddDisabled}
            />
          )}
        </div>
      }
    />
  );
}
