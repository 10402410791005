import Loader from '../../../Loader';

import styles from './OptionsLoading.module.scss';

export default function OptionsLoading() {
  return (
    <div className={styles.optionLoader}>
      <Loader className={styles.loader} />
      <div className={styles.loadingLabel}>Loading...</div>
    </div>
  );
}
