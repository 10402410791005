.container {
  position: relative;
}

.prefix {
  position: absolute;
  top: 4px;
  color: #BBBBBB;
  font-family: var(--font-default);
  left: -1px;
  font-size: 1.05rem;
}

.disabled .prefix {
  opacity: 0.7;
}


.input {
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.4;
  border: none;
  border-bottom: 1px solid var(--color-border);
  outline: none;
  transition: border-bottom-color 0.2s ease-in-out;
  font-family: var(--font-default);

  &::placeholder {
    color: var(--color-text-gray);
  }

  &:focus {
    border-bottom: 1px solid var(--color-brand);
  }
}

.input[type='number'] {
  -moz-appearance: textfield;
}

.input[type='number']::-webkit-inner-spin-button,
.input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.input::-ms-clear,
.input::-ms-reveal {
  display: none;
}

.input:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.input[type='search']::-webkit-search-cancel-button {
  width: 11px;
  height: 11px;
  background: transparent
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgdmlld0JveD0iMCAwIDExIDExIj4KICA8ZGVmcy8+CiAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMWExNzE3IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik04Ljc1IDEuOTdMMS42NCA5bTcuMDcuMDRMMS42OCAxLjkzIi8+Cjwvc3ZnPgo=')
    no-repeat;
  background-size: contain;
  appearance: none;
}

.input[type='search']::-ms-clear {
  width: 11px;
  height: 11px;
  background: transparent
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgdmlld0JveD0iMCAwIDExIDExIj4KICA8ZGVmcy8+CiAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMWExNzE3IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik04Ljc1IDEuOTdMMS42NCA5bTcuMDcuMDRMMS42OCAxLjkzIi8+Cjwvc3ZnPgo=')
    no-repeat;
  background-size: contain;
  appearance: none;
}
