import { FormGroup } from '../../../common/Form';
import { Fieldset } from '../../../common/Fieldset';

import { Block } from '../../../../ui/Block';
import { Column } from '../../../../ui/Column';
import { BrochuresCountFormField } from '../../../fields/BrochuresCountFormField';

export function BrochuresCountInfoFieldset() {
  return (
    <Block>
      <Fieldset title="Brochure order">
        <Column gap={24}>
          <FormGroup>
            <Column gap={24}>
              <BrochuresCountFormField
                name="services.brochures_count"
                label="Number of brochures"
                required
                additionalLabel="Specify number"
              />
            </Column>
          </FormGroup>
        </Column>
      </Fieldset>
    </Block>
  );
}
