import React from 'react';

export type AlignSelf =
  | 'auto'
  | 'baseline'
  | 'center'
  | 'flex-end'
  | 'flex-start'
  | 'stretch';

export interface FlexItemType {
  as?: React.ElementType;
  children?: React.ReactNode;
  className?: string;
  grow?: number;
  shrink?: number;
  align?: AlignSelf;
}
