import { FormError } from '../Form';
import { SubmissionErrorPropsType } from './SubmissionError.types';

export default function SubmissionError({
  className,
  error,
  codeMessageMapping,
}: SubmissionErrorPropsType) {
  if (!error) {
    return null;
  }

  let errorMessage;

  if (typeof error === 'string') {
    errorMessage = error;
  } else if (error && typeof error === 'object' && 'response' in error) {
    const responseData = error.response?.data;
    if (codeMessageMapping && error.response) {
      errorMessage =
        codeMessageMapping[error.response.status] || responseData?.message;
    } else if (
      responseData &&
      typeof responseData === 'object' &&
      'errors' in responseData
    ) {
      const { errors } = responseData;

      errorMessage = errors
        ?.map(({ path, message }) => `${path || ''}: ${message || ''}`)
        .join(', ');
    } else {
      errorMessage = responseData?.message;
    }
  } else if ('message' in error) {
    errorMessage = error.message;
  } else {
    errorMessage = error;
  }

  return <FormError className={className}>{errorMessage}</FormError>;
}
