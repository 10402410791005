.container {
  flex: 1;
  max-width: 450px;
}

.description {
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
}
