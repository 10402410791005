import Logo from './assets/logo.png';
import Logo2x from './assets/logo@2x.png';

import styles from './Header.module.scss';

export const Header: React.FC<{ title?: string }> = ({
  title = 'Sign in to AppClose',
}) => {
  return (
    <header className={styles.header}>
      <img
        srcSet={`${Logo}, ${Logo2x} 2x`}
        width={64}
        height={64}
        src={Logo2x}
        alt="AppClose"
      />
      <h1 className={styles.title}>{title}</h1>
    </header>
  );
};
