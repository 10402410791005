.container {
  position: relative;
  display: flex;
  gap: 5px;
  height: 36px;
  width: 100%;
  max-width: 576px;
}

.input {
  font-size: 1.25rem;
  line-height: 1.4;
  border: none;
  border-bottom: 1px solid var(--color-border);
  outline: none;
  transition: border-bottom-color 0.2s ease-in-out;
  font-family: var(--font-default);
  background-color: var(--color-white);
  color: var(--color-default);
}

.inactive {
  color: var(--color-text-gray);
  background-color: transparent;
}

.focus {
  color: var(--color-default);
  border-bottom: 1px solid var(--color-brand);
}

.error {
  border-bottom: 1px solid var(--color-error);
}
