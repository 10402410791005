import { useCallback } from 'react';

import { PhoneNumberFormFieldPropsType } from './PhoneNumberFormField.types';
import { PHONE_NUMBER_MASK } from '../../../../../constants/masks';
import { InputFormField } from '../../../Input/components/InputFormField';

export default function PhoneNumberFormField({
  name = 'phoneNumber',
  label = '',
  placeholder = '999 999 9999',
  ...props
}: PhoneNumberFormFieldPropsType) {
  const handleUnmaskValue = useCallback(
    (maskedValue) => maskedValue.replace(/ /g, ''),
    []
  );

  return (
    <InputFormField
      {...props}
      type="tel"
      name={name}
      label={label}
      placeholder={placeholder}
      mask={PHONE_NUMBER_MASK}
      unmaskValue={handleUnmaskValue}
    />
  );
}
