@import '../../scss/variables';
@import '../../scss/mixins';

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: initial;
  padding: initial;
  font-weight: bold;
  text-align: center;

  .label {
    padding: 0 0 0 15px;
  }

  &:hover {
    opacity: 1;

    .label {
      @include generate-skin-style('secondary', 'hover');
    }
  }

  &:active {
    .label {
      @include generate-skin-style('secondary', 'active');
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.plus {
  max-width: 100%;
  height: auto;
}

.primary {
  .icon {
    @include generate-skin-style('primary', 'base');
  }

  .plus {
    fill: get-skin-property-style('primary', 'base', 'color');
  }

  &:hover:not(.disabled) .icon {
    @include generate-skin-style('primary', 'hover');
  }

  &:active:not(.disabled) .icon {
    @include generate-skin-style('primary', 'active');
  }
}

.success {
  .icon {
    @include generate-skin-style('success', 'base');
  }

  .plus {
    fill: get-skin-property-style('success', 'base', 'color');
  }

  &:hover:not(.disabled) .icon {
    @include generate-skin-style('success', 'hover');
  }

  &:active:not(.disabled) .icon {
    @include generate-skin-style('success', 'active');
  }
}

.secondary {
  .icon {
    @include generate-skin-style('secondary', 'base');
  }

  .plus {
    fill: get-skin-property-style('secondary', 'base', 'color');
  }

  &:hover:not(.disabled) .icon {
    @include generate-skin-style('secondary', 'hover');
  }

  &:active:not(.disabled) .icon {
    @include generate-skin-style('secondary', 'active');
  }
}

.brand {
  .icon {
    @include generate-skin-style('brand', 'base');
  }

  .plus {
    fill: get-skin-property-style('brand', 'base', 'color');
  }

  &:hover:not(.disabled) .icon {
    @include generate-skin-style('brand', 'hover');
  }

  &:active:not(.disabled) .icon {
    @include generate-skin-style('brand', 'active');
  }
}

.info {
  .icon {
    @include generate-skin-style('info', 'base');
  }

  .plus {
    fill: get-skin-property-style('info', 'base', 'color');
  }

  &:hover:not(.disabled) .icon {
    @include generate-skin-style('info', 'hover');
  }

  &:active:not(.disabled) .icon {
    @include generate-skin-style('info', 'active');
  }
}

.loading {
  pointer-events: none;

  .icon {
    @include generate-skin-style('secondary', 'base');

    color: transparent;
    background-image: url('../../assets/spinner.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30px auto;

    > * {
      visibility: hidden;
    }
  }

  .label {
    @include generate-skin-style('secondary', 'hover');
  }

  &:hover,
  &:active {
    .icon {
      background-color: get-color('background', 'primary');
    }

    .label {
      @include generate-skin-style('secondary', 'hover');
    }
  }
}
