import classnames from 'classnames';

import { FieldsetPropsType } from './Fieldset.types';
import styles from './Fieldset.module.scss';
import { forwardRef } from 'react';

const Fieldset = forwardRef<HTMLFieldSetElement, FieldsetPropsType>(
  ({ title, className, children }, ref) => {
    return (
      <fieldset className={classnames(styles.fieldset, className)} ref={ref}>
        <legend className={styles.legend}>
          <div className={styles.legendContent}>
            <h4 className={styles.title}>{title}</h4>
          </div>
        </legend>
        <div className={styles.content}>{children}</div>
      </fieldset>
    );
  }
);

export default Fieldset;
