import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  Ref,
} from 'react';

export interface InputType {
  autoComplete?: 'on' | 'off';
  autoFocus?: boolean;
  className?: string;
  containerClassName?: string;
  defaultValue?: string;
  disabled?: boolean;
  disabledPaste?: boolean;
  hasError?: boolean;
  max?: number;
  maxLength?: number;
  min?: number;
  minLength?: number;
  name?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  prefix?: string;
  readOnly?: boolean;
  ref?: Ref<HTMLInputElement>;
  tabIndex?: number;
  type?: string;
  value?: string | number;
  step?: 'any' | number;
}
