import { FunctionComponent, ReactNode } from 'react';

import { ButtonTypes } from '../Button';

export type AddButtonSkinsType =
  | 'primary'
  | 'success'
  | 'secondary'
  | 'brand'
  | 'info';

export interface AddButtonType
  extends Omit<ButtonTypes, 'children' | 'style' | 'skin' | 'small'> {
  size?: number;
  icon?: FunctionComponent;
  iconSize?: number | string;
  label?: ReactNode;
  skin?: AddButtonSkinsType;
}
