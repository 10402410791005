import { useMemo } from 'react';
import { SelectFormField } from '../Select';
import { GenderSelectFormFieldPropsType } from './GenderSelectFormField.types';

export default function GenderSelectFormField({
  name = 'gender',
  label = 'Gender',
  ...props
}: GenderSelectFormFieldPropsType) {
  const genderTypes = {
    female: 'Female',
    male: 'Male',
    undefined: 'Other',
  };

  const genderTypesOptions: {
    label: string;
    value: any;
  }[] = useMemo(
    () =>
      Object.entries(genderTypes).map(([value, label]) => ({
        value: value,
        label,
      })),
    [genderTypes]
  );

  return (
    <SelectFormField
      name={name}
      label={label}
      options={genderTypesOptions}
      {...props}
    />
  );
}
