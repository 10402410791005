import { useContext } from 'react';
import { FetchContext } from '../../providers/Fetch';
import { ConfirmRequestData, ConfirmResponseData } from '../../types';
import { API_URLS } from '../../constants/apiConstants';

export const useConfirm = () => {
  const fetch = useContext(FetchContext);

  return async (data: ConfirmRequestData): Promise<ConfirmResponseData> => {
    try {
      const response = await fetch({
        url: API_URLS.CONFIRM_EMAIL,
        params: {
          data,
          options: {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        },
        errors: {
          400: 'Invalid code',
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };
};
