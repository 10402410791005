import { FieldProps } from 'formik';

import { InputFormFieldType } from '../../../Input';

export interface AmountFormFieldType
  extends Omit<
    InputFormFieldType,
    'type' | 'mask' | 'defaultValue' | 'onChange'
  > {
  withCurrency?: boolean;
  onChange?(value: number): void;
}

export interface AmountFormFieldWrapperType
  extends FieldProps,
    AmountFormFieldType {}
