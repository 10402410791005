.item {
  width: 100%;
}

.alignSelfBaseline {
  align-self: baseline;
}

.alignSelfAuto {
  align-self: auto;
}

.alignSelfCenter {
  align-self: center;
}

.alignSelfFlexEnd {
  align-self: flex-end;
}

.alignSelfFlexStart {
  align-self: flex-start;
}

.alignSelfStretch {
  align-self: stretch;
}
