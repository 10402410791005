import { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import useLanguages from '../../../hooks/useLanguages';
import { SelectFormField, SelectFormFieldPropsType } from '../Select';

export type LanguageSelectFormFieldPropsType = {
  name?: string;
  label?: string;
} & Omit<SelectFormFieldPropsType<string>, 'options' | 'name' | 'label'>;

export default function LanguageSelectFormField({
  name = 'language',
  label = 'Language',
  placeholder = 'Select language...',
  ...rest
}: LanguageSelectFormFieldPropsType) {
  const { languagesOptions } = useLanguages();
  const [options, setOptions] = useState(languagesOptions);

  const onSearchChange = useMemo(
    () =>
      debounce((search: string = '') => {
        if (!search) {
          setOptions(languagesOptions);

          return;
        }

        const filtered = languagesOptions.filter((option) => {
          return option.label.toLowerCase().includes(search.toLowerCase());
        });

        setOptions(filtered);
      }, 500),
    [setOptions, languagesOptions]
  );
  const handleOnBlur = useCallback(() => {
    setOptions(languagesOptions);
  }, [languagesOptions]);

  return (
    <SelectFormField
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      {...rest}
      withSearch
      onSearchChange={onSearchChange}
      onBlur={handleOnBlur}
    />
  );
}
