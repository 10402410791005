import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './Layout.module.scss';

export const Layout: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    // @ts-ignore
    window.dataLayer?.push({
      event: 'pageview',
      page: {
        url: location.pathname,
      },
    });
  }, [location]);

  return (
    <main className={styles.main}>
      <Outlet />
    </main>
  );
};
