import { ElementType, ReactNode } from 'react';

export interface GridType {
  as?: ElementType;
  columns?: string;
  rows?: string;
  areas?: string;
  template?: string;
  gap?: [number, number?];
  align?: 'start' | 'end' | 'center' | 'stretch' | 'baseline';
  justify?: 'start' | 'end' | 'center' | 'stretch';
  justifyContent?:
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  alignContent?:
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  autoColumns?: string;
  autoRows?: string;
  autoFlow?: 'row' | 'column' | 'row dense' | 'column dense';
  inline?: boolean;
  className?: string;
  children: ReactNode;
}
