import { CSSProperties, ElementType, ReactNode } from 'react';

type AlignContent =
  | 'center'
  | 'flex-end'
  | 'flex-start'
  | 'space-around'
  | 'space-between'
  | 'stretch';

type AlignItems = 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'stretch';

type FlexDirections = 'column-reverse' | 'column' | 'row-reverse' | 'row';

type FlexWraps = 'nowrap' | 'wrap-reverse' | 'wrap';

type JustifyContent =
  | 'center'
  | 'flex-end'
  | 'flex-start'
  | 'space-around'
  | 'space-between'
  | 'space-evenly'
  | 'stretch';

export interface FlexType {
  as?: ElementType;
  align?: AlignContent;
  alignItems?: AlignItems;
  direction?: FlexDirections;
  inline?: boolean;
  justify?: JustifyContent;
  wrap?: FlexWraps;
  fluid?: boolean;
  gap?: [number, number?];
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
  onClick?(e: React.MouseEvent): void;
  onContextMenu?(e: React.MouseEvent): void;
  onScroll?(e: React.UIEvent): void;
}
