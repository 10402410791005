import { CountryPhoneCodeSelectFormField } from '../CountryPhoneCodeSelectFormField';

import styles from './AppclosePhoneField.module.scss';
import { PhoneNumberFormField } from '../PhoneNumber';
import { InputFormField } from '../Input';
import { Flex, FlexItem } from '../../../ui/Flex';

type AppclosePhoneFieldPropsType = {
  phoneLabel: string;
  phoneName: string;
  codeName: string;
  codeValue?: string;
  disabled?: boolean;
  required?: boolean;
};

export function AppclosePhoneField({
  phoneLabel,
  phoneName,
  codeName,
  codeValue,
  disabled,
  required,
}: AppclosePhoneFieldPropsType) {
  return (
    <Flex gap={[24]} className={styles.container}>
      <FlexItem shrink={1} grow={0} className={styles.auto}>
        <CountryPhoneCodeSelectFormField
          name={codeName}
          disabled={disabled}
          label={phoneLabel}
          required={required}
        />
      </FlexItem>
      <FlexItem shrink={1}>
        {codeValue === 'US' ? (
          <PhoneNumberFormField name={phoneName} />
        ) : (
          <InputFormField minLength={7} maxLength={15} name={phoneName} />
        )}
      </FlexItem>
    </Flex>
  );
}
