import { createContext, useEffect, useState } from 'react';

export const QueryParamsContext = createContext<Record<string, string>>({});

type Props = {
  children: React.ReactNode;
};

export const QueryParamsProvider: React.FC<Props> = ({ children }) => {
  const [params, setParams] = useState<Record<string, string>>({});

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params: Record<string, string> = {};

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    setParams(params);
  }, []);

  return (
    <QueryParamsContext.Provider value={params}>
      {children}
    </QueryParamsContext.Provider>
  );
};
