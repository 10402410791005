import { useMemo } from 'react';
import data from './languages.json';

type Language = {
  code: string;
  name: string;
};

export default function useLanguages() {
  const languages: Language[] = useMemo(() => data, []);
  const getLanguageNameByCode = (languageCode: string) => {
    const language = languages.find(
      (language) => language.code === languageCode
    );

    return language?.name || '';
  };
  const languagesOptions = useMemo(
    () =>
      languages.map((language) => ({
        label: language.name,
        value: language.code,
      })),
    [languages]
  );

  return {
    languages,
    languagesOptions,
    getLanguageNameByCode,
  };
}
