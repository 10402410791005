
.deleteButton {
  display: none;
  margin: 5px 0;
}

.deletableGroup:hover .deleteButton {
  display: block;
}

.deleteButton svg {
  fill: var(--color-info);
}

.deleteButton:hover svg:hover {
  fill: var(--color-default);
}

