.textArea {
  width: 100%;
  max-height: 200px;
  padding: 4px 0 8px;
  background: no-repeat bottom;
  background-color: transparent;
  background-image: linear-gradient(
      0deg,
      var(--color-success) 1px,
      rgba(0, 150, 136, 0) 0
    ),
    linear-gradient(0deg, #e8ecec 1px, transparent 0) !important;
  background-size: 0 100%, 100% 100%;
  border: none;
  border-radius: 0;
  outline: none;
  transition: background 0s ease-out;
  resize: none;
  border-bottom: 1px solid var(--color-border);
  font-family: var(--font-default);
  font-size: 1.25rem;
  line-height: 1.4;
}

.textArea:focus {
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
}

.textArea::placeholder {
  color: var(--color-text-gray);
}
