import { ComponentType, ReactElement } from 'react';

import { FieldLabel } from './components/FieldLabel';
import styles from './field.module.scss';
import { FiledPropsType } from './field.types';
import classnames from 'classnames';
import { Column } from '../../../ui/Column';
import { FormError } from '../../common/Form';

const field = <TProps extends FiledPropsType<TProps>>({
  error,
  fieldClassName,
  label,
  hidden = false,
  hideErrorMsg = false,
  required = false,
  ...rest
}: TProps) => {
  return function (Component: ComponentType<any>): ReactElement<TProps> {
    const hasError = !!error;

    return (
      <div
        className={classnames(
          styles.field,
          {
            [styles.hidden]: hidden,
            [styles.showError]: hasError && !hideErrorMsg,
          },
          fieldClassName
        )}
      >
        <Column gap={8}>
          <FieldLabel required={required}>{label}</FieldLabel>
          <Component
            {...rest}
            className={classnames({ [styles.withError]: hasError })}
            hasError={hasError}
          />
          {hasError && !hideErrorMsg && <FormError>{error}</FormError>}
        </Column>
      </div>
    );
  };
};

export default field;
