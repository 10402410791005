import { FormGroup } from '../../../common/Form';
import { Fieldset } from '../../../common/Fieldset';
import {
  InputFormField,
  UpperFirstInputFormField,
} from '../../../fields/Input';
import { Block } from '../../../../ui/Block';
import { Column } from '../../../../ui/Column';

export function CompanyInfoFieldset() {
  return (
    <Block>
      <Fieldset title="Company information">
        <FormGroup>
          <Column gap={24}>
            <UpperFirstInputFormField
              name="company_info.company"
              required
              label="Company name"
              placeholder="Company name"
            />
            <InputFormField
              type="email"
              name="company_info.email"
              label="Email"
              required
              placeholder="Email"
            />
            <UpperFirstInputFormField
              name="company_info.website"
              label="Website"
              placeholder="Website"
            />
          </Column>
        </FormGroup>
      </Fieldset>
    </Block>
  );
}
