import { Link } from 'react-router-dom';
import { Button } from '../../ui/buttons';
import { Header } from '../Header';
import { Flex } from '../../ui/Flex';

import styles from './Forbidden.module.scss';

export const Forbidden: React.FC = () => {
  return (
    <Flex
      direction="column"
      justify="center"
      gap={[0, 12]}
      className={styles.container}
    >
      <Header title="Professional account only" />
      <Flex direction="column" alignItems="center" gap={[0, 36]}>
        <p className={styles.description}>
          The web platform is available only for professional accounts. You can
          access the system using your mobile app
        </p>
        <Link to="/">
          <Button skin="secondary">Go back</Button>
        </Link>
      </Flex>
    </Flex>
  );
};
