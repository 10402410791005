.control {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 30px;
  height: 40px;
  padding: 8px 0;
  border-radius: 15px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.05);
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 15px;
  opacity: 0.5;
  transition: opacity ease 0.5s;
}

.button:hover {
  opacity: 1;
}

.button:disabled {
  opacity: 0.1 !important;
}

.button:first-child {
  transform: rotate(-180deg);
}

.icon {
  position: absolute;
  top: -5px;
  pointer-events: none;
}
