import * as Sentry from '@sentry/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Layout } from './components/Layout';

import { SessionProvider } from './providers/Session';
import { QueryParamsProvider } from './providers/QueryParams';
import { FetchProvider } from './providers/Fetch';
import { SignIn } from './components/SignIn';
import { Forbidden } from './components/Forbidden';
import { routes } from './constants/routes';

const router = createBrowserRouter([
  {
    path: routes.home,
    Component: Layout,
    children: [
      {
        index: true,
        Component: SignIn,
      },
      {
        path: routes.forbidden,
        Component: Forbidden,
      },
    ],
  },
]);

export function App() {
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <QueryParamsProvider>
        <SessionProvider>
          <FetchProvider>
            <RouterProvider router={router} />
          </FetchProvider>
        </SessionProvider>
      </QueryParamsProvider>
    </Sentry.ErrorBoundary>
  );
}
