export type Inputs = {
  email: string;
  code: string;
  remember_me: boolean;
  phone_code?: string;
};

export type CheckRequestData = {
  email: string;
};

export type CheckResponseData = {
  link: string;
  verification_code: string;
};

export type ConfirmRequestData = {
  email: string;
  verification_code: string;
};

export type ConfirmResponseData = {
  access_token: string;
  just_signed_up?: boolean;
  professional?: any | null;
  firstname?: string | null;
  lastname?: string | null;
  created_at?: string | null;
};

export interface InitiateMfaRequestData {
  mfa_token?: string;
}

export interface InitiateMfaResponseData {
  mfa_token: string;
  mfa_code_ttl: number;
  masked_phone_number: string;
}

export interface ConfirmMfaRequestData {
  mfa_token: string;
  mfa_code: string;
}

export interface ConfirmMfaResponseData {
  access_token: string;
  account: any;
  just_signed_up: boolean;
}

export enum AppclosePhoneTypes {
  WORK = 'work',
  HOME = 'home',
  MOBILE = 'mobile',
  WORK_FAX = 'work-fax',
  HOME_FAX = 'home-fax',
}

enum RetainerFee {
  NoRetainer = 'no-retainer',
  RetainerAlways = 'retainer-always',
  RetainerSometimes = 'retainer-sometimes',
}

export type AdditionalPhoneNumber = {
  type: AppclosePhoneTypes;
  phone_number: string;
  phone_number_country: string;
};

export type PersonalInfo = {
  firstname: string;
  lastname: string;
  middlename?: string;
  avatar?: string;
  latitude?: number;
  longitude?: number;
  location_name?: string;
  title?: string;
  email?: string;
  additional_email?: string;
  country: string;
  state?: string;
  city: string;
  additional_address?: string;
  zip_code: string;
  phone_number: string;
  phone_number_country: string;
  phone_number_type?: AppclosePhoneTypes;
  additional_phone_numbers?: AdditionalPhoneNumber[];
};

export type LicenseInfo = {
  licensed_in?: string[];
  license_number?: string;
  licensed_since?: number;
  occupation?: string;
};

export type CompanyInfo = {
  company?: string;
  website?: string;
  email: string;
  additional_email?: string;
  country: string;
  state?: string;
  city: string;
  address: string;
  additional_address?: string;
  postal_code: string;
  phone_number?: string;
  phone_number_country?: string;
  phone_number_type?: string;
  additional_phone_numbers?: AdditionalPhoneNumber[];
};

export type ServicesInfo = {
  brochures_count?: number;
  hourly_fee?: string;
  retainer_fee?: RetainerFee;
  payment_types?: string[];
  free_consultation?: string;
  languages?: string[];
  description?: string;
  how_did_you_know?: string;
  public?: boolean;
};

export type ProfileUpdateRequestData = {
  personal?: PersonalInfo;
  license?: LicenseInfo;
  company_info?: CompanyInfo;
  services?: ServicesInfo;
};

export enum UsaStates {
  AL = 'AL',
  AK = 'AK',
  AS = 'AS',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FM = 'FM',
  FL = 'FL',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MH = 'MH',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  MP = 'MP',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PW = 'PW',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VI = 'VI',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export enum AppclosePaymentType {
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
  CHECK = 'CHECK',
}

export enum GenderTypes {
  male = 'male',
  female = 'femail',
  undefined = 'undefined',
}
