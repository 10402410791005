import { useCallback, useMemo } from 'react';
import { AppclosePhoneTypes } from '../../types';

export default function useAppclosePhoneTypes() {
  const phoneTypes: Record<AppclosePhoneTypes, string> = useMemo(
    () => ({
      [AppclosePhoneTypes.WORK_FAX]: 'Fax',
      [AppclosePhoneTypes.HOME]: 'Home',
      [AppclosePhoneTypes.MOBILE]: 'Mobile',
      [AppclosePhoneTypes.WORK]: 'Office',
      [AppclosePhoneTypes.HOME_FAX]: 'Home fax',
    }),
    []
  );

  const phoneTypesOptions: {
    label: string;
    value: AppclosePhoneTypes;
  }[] = useMemo(
    () =>
      Object.entries(phoneTypes).map(([value, label]) => ({
        value: value as AppclosePhoneTypes,
        label,
      })),
    [phoneTypes]
  );

  const getPhoneTypeLabel = useCallback(
    (type?: AppclosePhoneTypes | null, isPrimary?: boolean | undefined) => {
      if (!type) {
        return '';
      }

      if (isPrimary) {
        return 'Primary';
      }

      return phoneTypes[type as AppclosePhoneTypes];
    },
    [phoneTypes]
  );

  return { phoneTypesOptions, getPhoneTypeLabel };
}
