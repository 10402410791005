import React, { Ref } from 'react';
import classnames from 'classnames';

import { ButtonTypes } from './Button.types';
import styles from './Button.module.scss';

const Button = React.forwardRef(
  <Link extends string | undefined>(
    {
      onClick,
      loading = false,
      children,
      className,
      role = 'button',
      disabled,
      skin = 'secondary',
      style,
      small = false,
      type,
    }: ButtonTypes<Link>,
    ref: null | Link extends string
      ? Ref<HTMLAnchorElement | null>
      : Ref<HTMLButtonElement | null>
  ) => {
    const commonProps = {
      disabled: disabled || loading,
      className: classnames(
        styles.button,
        {
          [styles.loading]: loading,
          [styles.disabled]: disabled,
          [styles.small]: small,
          [styles.primary]: !loading && skin === 'primary',
          [styles.success]: !loading && skin === 'success',
          [styles.secondary]: !loading && skin === 'secondary',
          [styles.brand]: !loading && skin === 'brand',
          [styles.info]: !loading && skin === 'info',
          [styles.shell]: skin === 'shell',
          [styles.link]: skin === 'link',
        },
        className
      ),
      role,
      style,
    };

    return (
      <button
        {...commonProps}
        ref={ref as any}
        type={type || 'button'}
        onClick={onClick as any}
      >
        {children}
      </button>
    );
  }
);

export default Button;
