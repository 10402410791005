import { FormGroup } from '../../../common/Form';
import { Fieldset } from '../../../common/Fieldset';

import { Block } from '../../../../ui/Block';
import { Column } from '../../../../ui/Column';
import { AmountFormField } from '../../../fields/Amount';
import { RetainerSelectFormField } from '../../../fields/RetainerSelectFormField';
import { PaymentTypeSelectFormField } from '../../../fields/PaymentTypeSelectFormField';
import { InputFormField } from '../../../fields/Input';
import { LanguageSelectFormField } from '../../../fields/LanguageSelectFormField';
import { TextAreaFormField } from '../../../fields/TextArea';
import { CheckboxFormField } from '../../../fields/Checkbox';
import { Flex } from '../../../../ui/Flex';
import { Hint } from '../../../../ui/Hint';

export function ServicesInfoFieldset() {
  return (
    <Block>
      <Fieldset title="Services & fees">
        <FormGroup>
          <Column gap={24}>
            <AmountFormField
              name="services.hourly_fee"
              label="Hourly rate"
              placeholder="0.00"
            />
            <RetainerSelectFormField
              name="services.retainer_fee"
              label="Fee retainer"
              placeholder="Retainer"
            />
            <PaymentTypeSelectFormField
              name="services.payment_types"
              label="Payment types"
              placeholder="Payment types"
            />
            <InputFormField
              name="services.free_consultation"
              label="Free consultation"
              placeholder="Free consultation"
              maxLength={30}
            />
            <LanguageSelectFormField
              isMulti
              name="services.languages"
              label="Languages"
              placeholder="Languages"
            />
            <TextAreaFormField
              name="services.description"
              label="Services description"
              placeholder="Services description"
              maxLength={150}
            />
            <CheckboxFormField
              name="services.public"
              label={
                <Flex alignItems="center" gap={[12]}>
                  Visible in search as legal professional
                  <Hint content="If you would like AppClose users to be able to find and connect with you via AppClose, place a checkmark in this box." />
                </Flex>
              }
            />
          </Column>
        </FormGroup>
      </Fieldset>
    </Block>
  );
}
