import { Grid } from '../../../ui/Grid';
import { FormGridPropsType } from './FormGrid.types';

export default function FormGrid({
  gap = 60,
  align = 'start',
  className,
  children,
}: FormGridPropsType) {
  return (
    <Grid
      gap={[gap, 20]}
      columns="repeat(2, minmax(0, 1fr))"
      align={align}
      className={className}
    >
      {children}
    </Grid>
  );
}
