export function flattenObject(
  object: Record<string, any>
): Record<string, any> {
  function flatterObjectInner(
    object: Record<string, any>,
    prefix?: string
  ): Record<string, any> {
    const toReturn: Record<string, any> = {};
    prefix = prefix ? prefix + '.' : '';

    for (let i in object) {
      if (!object.hasOwnProperty(i)) {
        continue;
      }

      if (
        typeof object[i] === 'object' &&
        object[i] !== null &&
        !(object[i] instanceof Date) &&
        !(object[i] instanceof File) &&
        !(object[i] instanceof Blob)
      ) {
        Object.assign(toReturn, flatterObjectInner(object[i], prefix + i));
      } else {
        toReturn[prefix + i] = object[i];
      }
    }

    return toReturn;
  }

  return flatterObjectInner(object);
}
