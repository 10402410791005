.ellipsis {
  display: -webkit-box;
  max-width: 100%;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.noBox {
  display: block;
  white-space: nowrap;
}
