import { createContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';

export const SessionContext = createContext<string>('');

type Props = {
  children: React.ReactNode;
};

const SESSION_STORAGE_KEY = 'session';

export const SessionProvider: React.FC<Props> = ({ children }) => {
  const [session, setSession] = useState<string>('');

  useEffect(() => {
    const storedSession = window.sessionStorage.getItem(SESSION_STORAGE_KEY);

    if (storedSession) {
      setSession(storedSession);
    } else {
      const newSession = v4();
      window.sessionStorage.setItem(SESSION_STORAGE_KEY, newSession);
      setSession(newSession);
    }
  }, []);

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
};
