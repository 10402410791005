import { FieldArrayConfig, FieldArrayRenderProps } from 'formik';
import { ReactNode } from 'react';

export interface FormArrayFieldActions extends FieldArrayRenderProps {
  values: any[];
  resolveName(index: number, fieldName?: string): string;
  isArrayFieldDirty(index: number): boolean;
}

export interface FormArrayFieldPropsType
  extends Pick<FieldArrayConfig, 'name' | 'validateOnChange'> {
  children(props: FormArrayFieldActions): ReactNode;
}
