import { useContext } from 'react';
import { FetchContext } from '../../providers/Fetch';
import { InitiateMfaRequestData, InitiateMfaResponseData } from '../../types';
import { API_URLS } from '../../constants/apiConstants';

export const useInitiateMfa = () => {
  const fetch = useContext(FetchContext);

  return (data: InitiateMfaRequestData): Promise<InitiateMfaResponseData> =>
    fetch({
      url: API_URLS.INITIATE_MFA,
      params: {
        options: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-AppClose-MFA-Token': data.mfa_token,
          },
        },
        data: { send_to: 'phone_number' },
      },
      errors: {
        429000: 'Too many requests',
      },
    });
};
