import React, { ReactNode } from 'react';

export type ButtonSkinsType =
  | 'primary'
  | 'success'
  | 'secondary'
  | 'brand'
  | 'info'
  | 'shell'
  | 'link';

type ButtonCommonType = {
  loading?: boolean;
  disabled?: boolean;
  skin?: ButtonSkinsType;
  small?: boolean;
  className?: string;
  role?: string;
  children: ReactNode;
  style?: React.CSSProperties;
};

export type ButtonTypes<Link extends string | undefined = undefined> =
  ButtonCommonType & {
    link?: Link;
    type?: 'button' | 'submit' | 'reset';
    onClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  };
