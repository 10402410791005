.fieldset {
  min-width: 0;
  margin: 0 0 12px;
  padding: 0;
  border: none;
}

.legend {
  width: 100%;
  padding: 8px 16px;
  background-color: #F8F9FA;
  border-radius: 8px;
}

.legendContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
  margin: 0;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 900;
  letter-spacing: 1.7px;

}

.content {
  padding: 24px 12px 0;
}
