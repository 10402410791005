.form {
  width: 100%;
  min-width: 536px;
  max-width: 536px;

  .block {
    padding: 32px;
  }
}

.description {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;

  & > a {
    color: var(--color-brand);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.input {
  margin-top: 24px;
}

.error {
  text-align: center;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

@media only screen and (max-width: 600px) {
  .form {
    width: 300px;
    min-width: auto;
  }
}
