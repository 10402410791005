[
  { "code": "AW", "currency_code": "AWG", "name": "Aruba", "dial_code": "297" },
  {
    "code": "AF",
    "currency_code": "AFN",
    "name": "Afghanistan",
    "dial_code": "93"
  },
  {
    "code": "AO",
    "currency_code": "AOA",
    "name": "Angola",
    "dial_code": "244"
  },
  {
    "code": "AI",
    "currency_code": "XCD",
    "name": "Anguilla",
    "dial_code": "1264"
  },
  {
    "code": "AX",
    "currency_code": "EUR",
    "name": "Åland Islands",
    "dial_code": "358"
  },
  {
    "code": "AL",
    "currency_code": "ALL",
    "name": "Albania",
    "dial_code": "355"
  },
  {
    "code": "AD",
    "currency_code": "EUR",
    "name": "Andorra",
    "dial_code": "376"
  },
  {
    "code": "AE",
    "currency_code": "AED",
    "name": "United Arab Emirates",
    "dial_code": "971"
  },
  {
    "code": "AR",
    "currency_code": "ARS",
    "name": "Argentina",
    "dial_code": "54"
  },
  {
    "code": "AM",
    "currency_code": "AMD",
    "name": "Armenia",
    "dial_code": "374"
  },
  {
    "code": "AS",
    "currency_code": "USD",
    "name": "American Samoa",
    "dial_code": "1684"
  },
  {
    "code": "AG",
    "currency_code": "XCD",
    "name": "Antigua and Barbuda",
    "dial_code": "1268"
  },
  {
    "code": "AU",
    "currency_code": "AUD",
    "name": "Australia",
    "dial_code": "61"
  },
  {
    "code": "AT",
    "currency_code": "EUR",
    "name": "Austria",
    "dial_code": "43"
  },
  {
    "code": "AZ",
    "currency_code": "AZN",
    "name": "Azerbaijan",
    "dial_code": "994"
  },
  {
    "code": "BI",
    "currency_code": "BIF",
    "name": "Burundi",
    "dial_code": "257"
  },
  {
    "code": "BE",
    "currency_code": "EUR",
    "name": "Belgium",
    "dial_code": "32"
  },
  { "code": "BJ", "currency_code": "XOF", "name": "Benin", "dial_code": "229" },
  {
    "code": "BF",
    "currency_code": "XOF",
    "name": "Burkina Faso",
    "dial_code": "226"
  },
  {
    "code": "BD",
    "currency_code": "BDT",
    "name": "Bangladesh",
    "dial_code": "880"
  },
  {
    "code": "BG",
    "currency_code": "BGN",
    "name": "Bulgaria",
    "dial_code": "359"
  },
  {
    "code": "BH",
    "currency_code": "BHD",
    "name": "Bahrain",
    "dial_code": "973"
  },
  {
    "code": "BS",
    "currency_code": "BSD",
    "name": "Bahamas",
    "dial_code": "1242"
  },
  {
    "code": "BA",
    "currency_code": "BAM",
    "name": "Bosnia and Herzegovina",
    "dial_code": "387"
  },
  {
    "code": "BL",
    "currency_code": "EUR",
    "name": "Saint Barthélemy",
    "dial_code": "590"
  },
  {
    "code": "BY",
    "currency_code": "BYN",
    "name": "Belarus",
    "dial_code": "375"
  },
  {
    "code": "BZ",
    "currency_code": "BZD",
    "name": "Belize",
    "dial_code": "501"
  },
  {
    "code": "BM",
    "currency_code": "BMD",
    "name": "Bermuda",
    "dial_code": "1441"
  },
  {
    "code": "BO",
    "currency_code": "BOB",
    "name": "Bolivia",
    "dial_code": "591"
  },
  { "code": "BR", "currency_code": "BRL", "name": "Brazil", "dial_code": "55" },
  {
    "code": "BB",
    "currency_code": "BBD",
    "name": "Barbados",
    "dial_code": "1246"
  },
  {
    "code": "BN",
    "currency_code": "BND",
    "name": "Brunei",
    "dial_code": "673"
  },
  {
    "code": "BT",
    "currency_code": "BTN",
    "name": "Bhutan",
    "dial_code": "975"
  },
  {
    "code": "BW",
    "currency_code": "BWP",
    "name": "Botswana",
    "dial_code": "267"
  },
  {
    "code": "CF",
    "currency_code": "XAF",
    "name": "Central African Republic",
    "dial_code": "236"
  },
  { "code": "CA", "currency_code": "CAD", "name": "Canada", "dial_code": "1" },
  {
    "code": "CC",
    "currency_code": "AUD",
    "name": "Cocos (Keeling) Islands",
    "dial_code": "61"
  },
  {
    "code": "CH",
    "currency_code": "CHF",
    "name": "Switzerland",
    "dial_code": "41"
  },
  { "code": "CL", "currency_code": "CLP", "name": "Chile", "dial_code": "56" },
  { "code": "CN", "currency_code": "CNY", "name": "China", "dial_code": "86" },
  {
    "code": "CI",
    "currency_code": "XOF",
    "name": "Ivory Coast",
    "dial_code": "225"
  },
  {
    "code": "CM",
    "currency_code": "XAF",
    "name": "Cameroon",
    "dial_code": "237"
  },
  {
    "code": "CD",
    "currency_code": "CDF",
    "name": "DR Congo",
    "dial_code": "243"
  },
  {
    "code": "CG",
    "currency_code": "XAF",
    "name": "Republic of the Congo",
    "dial_code": "242"
  },
  {
    "code": "CK",
    "currency_code": "NZD",
    "name": "Cook Islands",
    "dial_code": "682"
  },
  {
    "code": "CO",
    "currency_code": "COP",
    "name": "Colombia",
    "dial_code": "57"
  },
  {
    "code": "KM",
    "currency_code": "KMF",
    "name": "Comoros",
    "dial_code": "269"
  },
  {
    "code": "CV",
    "currency_code": "CVE",
    "name": "Cape Verde",
    "dial_code": "238"
  },
  {
    "code": "CR",
    "currency_code": "CRC",
    "name": "Costa Rica",
    "dial_code": "506"
  },
  { "code": "CU", "currency_code": "CUP", "name": "Cuba", "dial_code": "53" },
  {
    "code": "CW",
    "currency_code": "ANG",
    "name": "Curaçao",
    "dial_code": "5999"
  },
  {
    "code": "CX",
    "currency_code": "AUD",
    "name": "Christmas Island",
    "dial_code": "61"
  },
  {
    "code": "KY",
    "currency_code": "KYD",
    "name": "Cayman Islands",
    "dial_code": "1345"
  },
  {
    "code": "CY",
    "currency_code": "EUR",
    "name": "Cyprus",
    "dial_code": "357"
  },
  {
    "code": "CZ",
    "currency_code": "CZK",
    "name": "Czechia",
    "dial_code": "420"
  },
  {
    "code": "DE",
    "currency_code": "EUR",
    "name": "Germany",
    "dial_code": "49"
  },
  {
    "code": "DJ",
    "currency_code": "DJF",
    "name": "Djibouti",
    "dial_code": "253"
  },
  {
    "code": "DM",
    "currency_code": "XCD",
    "name": "Dominica",
    "dial_code": "1767"
  },
  {
    "code": "DK",
    "currency_code": "DKK",
    "name": "Denmark",
    "dial_code": "45"
  },
  {
    "code": "DO",
    "currency_code": "DOP",
    "name": "Dominican Republic",
    "dial_code": "1809"
  },
  {
    "code": "DO",
    "currency_code": "DOP",
    "name": "Dominican Republic",
    "dial_code": "1829"
  },
  {
    "code": "DO",
    "currency_code": "DOP",
    "name": "Dominican Republic",
    "dial_code": "1849"
  },
  {
    "code": "DZ",
    "currency_code": "DZD",
    "name": "Algeria",
    "dial_code": "213"
  },
  {
    "code": "EC",
    "currency_code": "USD",
    "name": "Ecuador",
    "dial_code": "593"
  },
  { "code": "EG", "currency_code": "EGP", "name": "Egypt", "dial_code": "20" },
  {
    "code": "ER",
    "currency_code": "ERN",
    "name": "Eritrea",
    "dial_code": "291"
  },
  {
    "code": "EH",
    "currency_code": "MAD",
    "name": "Western Sahara",
    "dial_code": "212"
  },
  { "code": "ES", "currency_code": "EUR", "name": "Spain", "dial_code": "34" },
  {
    "code": "EE",
    "currency_code": "EUR",
    "name": "Estonia",
    "dial_code": "372"
  },
  {
    "code": "ET",
    "currency_code": "ETB",
    "name": "Ethiopia",
    "dial_code": "251"
  },
  {
    "code": "FI",
    "currency_code": "EUR",
    "name": "Finland",
    "dial_code": "358"
  },
  { "code": "FJ", "currency_code": "FJD", "name": "Fiji", "dial_code": "679" },
  {
    "code": "FK",
    "currency_code": "FKP",
    "name": "Falkland Islands",
    "dial_code": "500"
  },
  { "code": "FR", "currency_code": "EUR", "name": "France", "dial_code": "33" },
  {
    "code": "FO",
    "currency_code": "DKK",
    "name": "Faroe Islands",
    "dial_code": "298"
  },
  {
    "code": "FM",
    "currency_code": "USD",
    "name": "Micronesia",
    "dial_code": "691"
  },
  { "code": "GA", "currency_code": "XAF", "name": "Gabon", "dial_code": "241" },
  {
    "code": "GB",
    "currency_code": "GBP",
    "name": "United Kingdom",
    "dial_code": "44"
  },
  {
    "code": "GE",
    "currency_code": "GEL",
    "name": "Georgia",
    "dial_code": "995"
  },
  {
    "code": "GG",
    "currency_code": "GBP",
    "name": "Guernsey",
    "dial_code": "44"
  },
  { "code": "GH", "currency_code": "GHS", "name": "Ghana", "dial_code": "233" },
  {
    "code": "GI",
    "currency_code": "GIP",
    "name": "Gibraltar",
    "dial_code": "350"
  },
  {
    "code": "GN",
    "currency_code": "GNF",
    "name": "Guinea",
    "dial_code": "224"
  },
  {
    "code": "GP",
    "currency_code": "EUR",
    "name": "Guadeloupe",
    "dial_code": "590"
  },
  {
    "code": "GM",
    "currency_code": "GMD",
    "name": "Gambia",
    "dial_code": "220"
  },
  {
    "code": "GW",
    "currency_code": "XOF",
    "name": "Guinea-Bissau",
    "dial_code": "245"
  },
  {
    "code": "GQ",
    "currency_code": "XAF",
    "name": "Equatorial Guinea",
    "dial_code": "240"
  },
  { "code": "GR", "currency_code": "EUR", "name": "Greece", "dial_code": "30" },
  {
    "code": "GD",
    "currency_code": "XCD",
    "name": "Grenada",
    "dial_code": "1473"
  },
  {
    "code": "GL",
    "currency_code": "DKK",
    "name": "Greenland",
    "dial_code": "299"
  },
  {
    "code": "GT",
    "currency_code": "GTQ",
    "name": "Guatemala",
    "dial_code": "502"
  },
  {
    "code": "GF",
    "currency_code": "EUR",
    "name": "French Guiana",
    "dial_code": "594"
  },
  { "code": "GU", "currency_code": "USD", "name": "Guam", "dial_code": "1671" },
  {
    "code": "GY",
    "currency_code": "GYD",
    "name": "Guyana",
    "dial_code": "592"
  },
  {
    "code": "HK",
    "currency_code": "HKD",
    "name": "Hong Kong",
    "dial_code": "852"
  },
  {
    "code": "HN",
    "currency_code": "HNL",
    "name": "Honduras",
    "dial_code": "504"
  },
  {
    "code": "HR",
    "currency_code": "HRK",
    "name": "Croatia",
    "dial_code": "385"
  },
  { "code": "HT", "currency_code": "HTG", "name": "Haiti", "dial_code": "509" },
  {
    "code": "HU",
    "currency_code": "HUF",
    "name": "Hungary",
    "dial_code": "36"
  },
  {
    "code": "ID",
    "currency_code": "IDR",
    "name": "Indonesia",
    "dial_code": "62"
  },
  {
    "code": "IM",
    "currency_code": "GBP",
    "name": "Isle of Man",
    "dial_code": "44"
  },
  { "code": "IN", "currency_code": "INR", "name": "India", "dial_code": "91" },
  {
    "code": "IO",
    "currency_code": "USD",
    "name": "British Indian Ocean Territory",
    "dial_code": "246"
  },
  {
    "code": "IE",
    "currency_code": "EUR",
    "name": "Ireland",
    "dial_code": "353"
  },
  { "code": "IR", "currency_code": "IRR", "name": "Iran", "dial_code": "98" },
  { "code": "IQ", "currency_code": "IQD", "name": "Iraq", "dial_code": "964" },
  {
    "code": "IS",
    "currency_code": "ISK",
    "name": "Iceland",
    "dial_code": "354"
  },
  {
    "code": "IL",
    "currency_code": "ILS",
    "name": "Israel",
    "dial_code": "972"
  },
  { "code": "IT", "currency_code": "EUR", "name": "Italy", "dial_code": "39" },
  {
    "code": "JM",
    "currency_code": "JMD",
    "name": "Jamaica",
    "dial_code": "1876"
  },
  { "code": "JE", "currency_code": "GBP", "name": "Jersey", "dial_code": "44" },
  {
    "code": "JO",
    "currency_code": "JOD",
    "name": "Jordan",
    "dial_code": "962"
  },
  { "code": "JP", "currency_code": "JPY", "name": "Japan", "dial_code": "81" },
  {
    "code": "KZ",
    "currency_code": "KZT",
    "name": "Kazakhstan",
    "dial_code": "76"
  },
  {
    "code": "KZ",
    "currency_code": "KZT",
    "name": "Kazakhstan",
    "dial_code": "77"
  },
  { "code": "KE", "currency_code": "KES", "name": "Kenya", "dial_code": "254" },
  {
    "code": "KG",
    "currency_code": "KGS",
    "name": "Kyrgyzstan",
    "dial_code": "996"
  },
  {
    "code": "KH",
    "currency_code": "KHR",
    "name": "Cambodia",
    "dial_code": "855"
  },
  {
    "code": "KI",
    "currency_code": "AUD",
    "name": "Kiribati",
    "dial_code": "686"
  },
  {
    "code": "SH",
    "currency_code": "SHP",
    "name": "Saint Helena",
    "dial_code": "290"
  },
  {
    "code": "KN",
    "currency_code": "XCD",
    "name": "Saint Kitts and Nevis",
    "dial_code": "1869"
  },
  {
    "code": "KR",
    "currency_code": "KRW",
    "name": "South Korea",
    "dial_code": "82"
  },
  {
    "code": "XK",
    "currency_code": "EUR",
    "name": "Kosovo",
    "dial_code": "383"
  },
  {
    "code": "KW",
    "currency_code": "KWD",
    "name": "Kuwait",
    "dial_code": "965"
  },
  { "code": "LA", "currency_code": "LAK", "name": "Laos", "dial_code": "856" },
  {
    "code": "LB",
    "currency_code": "LBP",
    "name": "Lebanon",
    "dial_code": "961"
  },
  {
    "code": "LR",
    "currency_code": "LRD",
    "name": "Liberia",
    "dial_code": "231"
  },
  { "code": "LY", "currency_code": "LYD", "name": "Libya", "dial_code": "218" },
  {
    "code": "LC",
    "currency_code": "XCD",
    "name": "Saint Lucia",
    "dial_code": "1758"
  },
  {
    "code": "LI",
    "currency_code": "CHF",
    "name": "Liechtenstein",
    "dial_code": "423"
  },
  {
    "code": "LK",
    "currency_code": "LKR",
    "name": "Sri Lanka",
    "dial_code": "94"
  },
  {
    "code": "LS",
    "currency_code": "LSL",
    "name": "Lesotho",
    "dial_code": "266"
  },
  {
    "code": "LT",
    "currency_code": "EUR",
    "name": "Lithuania",
    "dial_code": "370"
  },
  {
    "code": "LU",
    "currency_code": "EUR",
    "name": "Luxembourg",
    "dial_code": "352"
  },
  {
    "code": "LV",
    "currency_code": "EUR",
    "name": "Latvia",
    "dial_code": "371"
  },
  { "code": "MO", "currency_code": "MOP", "name": "Macau", "dial_code": "853" },
  {
    "code": "MF",
    "currency_code": "EUR",
    "name": "Saint Martin",
    "dial_code": "590"
  },
  {
    "code": "MA",
    "currency_code": "MAD",
    "name": "Morocco",
    "dial_code": "212"
  },
  {
    "code": "MC",
    "currency_code": "EUR",
    "name": "Monaco",
    "dial_code": "377"
  },
  {
    "code": "MD",
    "currency_code": "MDL",
    "name": "Moldova",
    "dial_code": "373"
  },
  {
    "code": "MG",
    "currency_code": "MGA",
    "name": "Madagascar",
    "dial_code": "261"
  },
  {
    "code": "MV",
    "currency_code": "MVR",
    "name": "Maldives",
    "dial_code": "960"
  },
  { "code": "MX", "currency_code": "MXN", "name": "Mexico", "dial_code": "52" },
  {
    "code": "MH",
    "currency_code": "USD",
    "name": "Marshall Islands",
    "dial_code": "692"
  },
  {
    "code": "MK",
    "currency_code": "MKD",
    "name": "Macedonia",
    "dial_code": "389"
  },
  { "code": "ML", "currency_code": "XOF", "name": "Mali", "dial_code": "223" },
  { "code": "MT", "currency_code": "EUR", "name": "Malta", "dial_code": "356" },
  {
    "code": "MM",
    "currency_code": "MMK",
    "name": "Myanmar",
    "dial_code": "95"
  },
  {
    "code": "ME",
    "currency_code": "EUR",
    "name": "Montenegro",
    "dial_code": "382"
  },
  {
    "code": "MN",
    "currency_code": "MNT",
    "name": "Mongolia",
    "dial_code": "976"
  },
  {
    "code": "MP",
    "currency_code": "USD",
    "name": "Northern Mariana Islands",
    "dial_code": "1670"
  },
  {
    "code": "MZ",
    "currency_code": "MZN",
    "name": "Mozambique",
    "dial_code": "258"
  },
  {
    "code": "MR",
    "currency_code": "MRO",
    "name": "Mauritania",
    "dial_code": "222"
  },
  {
    "code": "MS",
    "currency_code": "XCD",
    "name": "Montserrat",
    "dial_code": "1664"
  },
  {
    "code": "MQ",
    "currency_code": "EUR",
    "name": "Martinique",
    "dial_code": "596"
  },
  {
    "code": "MU",
    "currency_code": "MUR",
    "name": "Mauritius",
    "dial_code": "230"
  },
  {
    "code": "MW",
    "currency_code": "MWK",
    "name": "Malawi",
    "dial_code": "265"
  },
  {
    "code": "MY",
    "currency_code": "MYR",
    "name": "Malaysia",
    "dial_code": "60"
  },
  {
    "code": "YT",
    "currency_code": "EUR",
    "name": "Mayotte",
    "dial_code": "262"
  },
  {
    "code": "NA",
    "currency_code": "NAD",
    "name": "Namibia",
    "dial_code": "264"
  },
  {
    "code": "NC",
    "currency_code": "XPF",
    "name": "New Caledonia",
    "dial_code": "687"
  },
  { "code": "NE", "currency_code": "XOF", "name": "Niger", "dial_code": "227" },
  {
    "code": "NF",
    "currency_code": "AUD",
    "name": "Norfolk Island",
    "dial_code": "672"
  },
  {
    "code": "NG",
    "currency_code": "NGN",
    "name": "Nigeria",
    "dial_code": "234"
  },
  {
    "code": "NI",
    "currency_code": "NIO",
    "name": "Nicaragua",
    "dial_code": "505"
  },
  { "code": "NU", "currency_code": "NZD", "name": "Niue", "dial_code": "683" },
  {
    "code": "NL",
    "currency_code": "EUR",
    "name": "Netherlands",
    "dial_code": "31"
  },
  { "code": "NO", "currency_code": "NOK", "name": "Norway", "dial_code": "47" },
  { "code": "NP", "currency_code": "NPR", "name": "Nepal", "dial_code": "977" },
  { "code": "NR", "currency_code": "AUD", "name": "Nauru", "dial_code": "674" },
  {
    "code": "NZ",
    "currency_code": "NZD",
    "name": "New Zealand",
    "dial_code": "64"
  },
  { "code": "OM", "currency_code": "OMR", "name": "Oman", "dial_code": "968" },
  {
    "code": "PK",
    "currency_code": "PKR",
    "name": "Pakistan",
    "dial_code": "92"
  },
  {
    "code": "PA",
    "currency_code": "PAB",
    "name": "Panama",
    "dial_code": "507"
  },
  {
    "code": "PN",
    "currency_code": "NZD",
    "name": "Pitcairn Islands",
    "dial_code": "64"
  },
  { "code": "PE", "currency_code": "PEN", "name": "Peru", "dial_code": "51" },
  {
    "code": "PH",
    "currency_code": "PHP",
    "name": "Philippines",
    "dial_code": "63"
  },
  { "code": "PW", "currency_code": "USD", "name": "Palau", "dial_code": "680" },
  {
    "code": "PG",
    "currency_code": "PGK",
    "name": "Papua New Guinea",
    "dial_code": "675"
  },
  { "code": "PL", "currency_code": "PLN", "name": "Poland", "dial_code": "48" },
  {
    "code": "PR",
    "currency_code": "USD",
    "name": "Puerto Rico",
    "dial_code": "1787"
  },
  {
    "code": "PR",
    "currency_code": "USD",
    "name": "Puerto Rico",
    "dial_code": "1939"
  },
  {
    "code": "KP",
    "currency_code": "KPW",
    "name": "North Korea",
    "dial_code": "850"
  },
  {
    "code": "PT",
    "currency_code": "EUR",
    "name": "Portugal",
    "dial_code": "351"
  },
  {
    "code": "PY",
    "currency_code": "PYG",
    "name": "Paraguay",
    "dial_code": "595"
  },
  {
    "code": "PS",
    "currency_code": "ILS",
    "name": "Palestine",
    "dial_code": "970"
  },
  {
    "code": "PF",
    "currency_code": "XPF",
    "name": "French Polynesia",
    "dial_code": "689"
  },
  { "code": "QA", "currency_code": "QAR", "name": "Qatar", "dial_code": "974" },
  {
    "code": "RE",
    "currency_code": "EUR",
    "name": "Réunion",
    "dial_code": "262"
  },
  {
    "code": "RO",
    "currency_code": "RON",
    "name": "Romania",
    "dial_code": "40"
  },
  { "code": "RU", "currency_code": "RUB", "name": "Russia", "dial_code": "7" },
  {
    "code": "RW",
    "currency_code": "RWF",
    "name": "Rwanda",
    "dial_code": "250"
  },
  {
    "code": "SA",
    "currency_code": "SAR",
    "name": "Saudi Arabia",
    "dial_code": "966"
  },
  { "code": "SD", "currency_code": "SDG", "name": "Sudan", "dial_code": "249" },
  {
    "code": "SN",
    "currency_code": "XOF",
    "name": "Senegal",
    "dial_code": "221"
  },
  {
    "code": "SG",
    "currency_code": "SGD",
    "name": "Singapore",
    "dial_code": "65"
  },
  {
    "code": "GS",
    "currency_code": "GBP",
    "name": "South Georgia",
    "dial_code": "500"
  },
  {
    "code": "SJ",
    "currency_code": "NOK",
    "name": "Svalbard and Jan Mayen",
    "dial_code": "4779"
  },
  {
    "code": "SB",
    "currency_code": "SBD",
    "name": "Solomon Islands",
    "dial_code": "677"
  },
  {
    "code": "SL",
    "currency_code": "SLL",
    "name": "Sierra Leone",
    "dial_code": "232"
  },
  {
    "code": "SV",
    "currency_code": "USD",
    "name": "El Salvador",
    "dial_code": "503"
  },
  {
    "code": "SM",
    "currency_code": "EUR",
    "name": "San Marino",
    "dial_code": "378"
  },
  {
    "code": "SO",
    "currency_code": "SOS",
    "name": "Somalia",
    "dial_code": "252"
  },
  {
    "code": "PM",
    "currency_code": "EUR",
    "name": "Saint Pierre and Miquelon",
    "dial_code": "508"
  },
  {
    "code": "RS",
    "currency_code": "RSD",
    "name": "Serbia",
    "dial_code": "381"
  },
  {
    "code": "SS",
    "currency_code": "SSP",
    "name": "South Sudan",
    "dial_code": "211"
  },
  {
    "code": "ST",
    "currency_code": "STD",
    "name": "São Tomé and Príncipe",
    "dial_code": "239"
  },
  {
    "code": "SR",
    "currency_code": "SRD",
    "name": "Suriname",
    "dial_code": "597"
  },
  {
    "code": "SK",
    "currency_code": "EUR",
    "name": "Slovakia",
    "dial_code": "421"
  },
  {
    "code": "SI",
    "currency_code": "EUR",
    "name": "Slovenia",
    "dial_code": "386"
  },
  { "code": "SE", "currency_code": "SEK", "name": "Sweden", "dial_code": "46" },
  {
    "code": "SZ",
    "currency_code": "SZL",
    "name": "Swaziland",
    "dial_code": "268"
  },
  {
    "code": "SX",
    "currency_code": "ANG",
    "name": "Sint Maarten",
    "dial_code": "1721"
  },
  {
    "code": "SC",
    "currency_code": "SCR",
    "name": "Seychelles",
    "dial_code": "248"
  },
  { "code": "SY", "currency_code": "SYP", "name": "Syria", "dial_code": "963" },
  {
    "code": "TC",
    "currency_code": "USD",
    "name": "Turks and Caicos Islands",
    "dial_code": "1649"
  },
  { "code": "TD", "currency_code": "XAF", "name": "Chad", "dial_code": "235" },
  { "code": "TG", "currency_code": "XOF", "name": "Togo", "dial_code": "228" },
  {
    "code": "TH",
    "currency_code": "THB",
    "name": "Thailand",
    "dial_code": "66"
  },
  {
    "code": "TJ",
    "currency_code": "TJS",
    "name": "Tajikistan",
    "dial_code": "992"
  },
  {
    "code": "TK",
    "currency_code": "NZD",
    "name": "Tokelau",
    "dial_code": "690"
  },
  {
    "code": "TM",
    "currency_code": "TMT",
    "name": "Turkmenistan",
    "dial_code": "993"
  },
  {
    "code": "TL",
    "currency_code": "USD",
    "name": "Timor-Leste",
    "dial_code": "670"
  },
  { "code": "TO", "currency_code": "TOP", "name": "Tonga", "dial_code": "676" },
  {
    "code": "TT",
    "currency_code": "TTD",
    "name": "Trinidad and Tobago",
    "dial_code": "1868"
  },
  {
    "code": "TN",
    "currency_code": "TND",
    "name": "Tunisia",
    "dial_code": "216"
  },
  { "code": "TR", "currency_code": "TRY", "name": "Turkey", "dial_code": "90" },
  {
    "code": "TV",
    "currency_code": "AUD",
    "name": "Tuvalu",
    "dial_code": "688"
  },
  {
    "code": "TW",
    "currency_code": "TWD",
    "name": "Taiwan",
    "dial_code": "886"
  },
  {
    "code": "TZ",
    "currency_code": "TZS",
    "name": "Tanzania",
    "dial_code": "255"
  },
  {
    "code": "UG",
    "currency_code": "UGX",
    "name": "Uganda",
    "dial_code": "256"
  },
  {
    "code": "UA",
    "currency_code": "UAH",
    "name": "Ukraine",
    "dial_code": "380"
  },
  {
    "code": "UY",
    "currency_code": "UYU",
    "name": "Uruguay",
    "dial_code": "598"
  },
  {
    "code": "US",
    "currency_code": "USD",
    "name": "United States",
    "dial_code": "1"
  },
  {
    "code": "UZ",
    "currency_code": "UZS",
    "name": "Uzbekistan",
    "dial_code": "998"
  },
  {
    "code": "VA",
    "currency_code": "EUR",
    "name": "Vatican City",
    "dial_code": "379"
  },
  {
    "code": "VC",
    "currency_code": "XCD",
    "name": "Saint Vincent and the Grenadines",
    "dial_code": "1784"
  },
  {
    "code": "VE",
    "currency_code": "VEF",
    "name": "Venezuela",
    "dial_code": "58"
  },
  {
    "code": "VG",
    "currency_code": "USD",
    "name": "British Virgin Islands",
    "dial_code": "1284"
  },
  {
    "code": "VI",
    "currency_code": "USD",
    "name": "United States Virgin Islands",
    "dial_code": "1340"
  },
  {
    "code": "VN",
    "currency_code": "VND",
    "name": "Vietnam",
    "dial_code": "84"
  },
  {
    "code": "VU",
    "currency_code": "VUV",
    "name": "Vanuatu",
    "dial_code": "678"
  },
  {
    "code": "WF",
    "currency_code": "XPF",
    "name": "Wallis and Futuna",
    "dial_code": "681"
  },
  { "code": "WS", "currency_code": "WST", "name": "Samoa", "dial_code": "685" },
  { "code": "YE", "currency_code": "YER", "name": "Yemen", "dial_code": "967" },
  {
    "code": "ZA",
    "currency_code": "ZAR",
    "name": "South Africa",
    "dial_code": "27"
  },
  {
    "code": "ZM",
    "currency_code": "ZMW",
    "name": "Zambia",
    "dial_code": "260"
  },
  {
    "code": "ZW",
    "currency_code": "ZWL",
    "name": "Zimbabwe",
    "dial_code": "263"
  }
]
