import { FocusEvent, ChangeEvent, KeyboardEventHandler } from 'react';

export type TextAreaPropsType = {
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  expandable?: boolean;
  hasError?: boolean;
  maxLength?: number;
  minLength?: number;
  name?: string;
  onBlur?(event: FocusEvent<HTMLTextAreaElement>): void;
  onChange?(event: ChangeEvent<HTMLTextAreaElement>): void;
  onFocus?(event: FocusEvent<HTMLTextAreaElement>): void;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  onKeyPress?: KeyboardEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  rows?: number;
  value?: string;
};
