import styles from './Column.module.scss';

type Props = {
  children: React.ReactNode;
  gap?: number;
};

export const Column: React.FC<Props> = ({ children, gap }) => {
  return (
    <div className={styles.container} style={{ gap: `${gap}px` }}>
      {children}
    </div>
  );
};
