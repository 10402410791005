import { FieldProps } from 'formik';

import { FiledPropsType } from '../../../../hoc/field';
import { MaskedInputFiledType } from '../MaskedInput/MaskedInput.types';
import { InputType } from '../../../../../ui/Input';

export type InputFormFieldType = FiledPropsType<
  Omit<InputType, 'value'> & Partial<MaskedInputFiledType>
> & {
  upperFirst?: boolean;
  trimSpaces?: boolean;
};

export type InputFormFieldWrapperType = FieldProps & InputFormFieldType;
