@import '../../scss/variables';

.option {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $optionXPadding;
  border-radius: 4px;
  cursor: pointer;
}

.highlighted {
  background-color: var(--color-background);
}

.label {
  flex: 1 1 100%;
  margin: 0;
  padding: 8px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icon {
  flex-shrink: 0;
  margin-left: 10px;
  padding: 0;
  fill: var(--color-success);
}

.option:not(.selected) .icon {
  visibility: hidden;
}
