import * as Yup from 'yup';
import { EMAIL_VALIDATION_SCHEMA } from '../../schemas/validations/email';

export const getValidationSchema = (step: number) => {
  switch (step) {
    case 0:
      return Yup.object({
        email: EMAIL_VALIDATION_SCHEMA,
      });
    case 1:
      return Yup.object({
        code: Yup.string(),
      });
    default:
      return undefined;
  }
};
