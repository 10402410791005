import { useState } from 'react';

import { Column } from '../../ui/Column';
import { Block } from '../../ui/Block';
import { Button } from '../../ui/buttons';

import { Form } from '../common/Form';
import { InputFormField } from '../fields/Input';
import { Inputs } from '../../types';
import { getValidationSchema } from '../SignIn/SignIn.schema';
import FormSubmissionError from '../common/Form/components/FormSubmissionError';
import { Verification } from '../fields/Verification';
import { Flex } from '../../ui/Flex';
import { CheckboxFormField } from '../fields/Checkbox';
import { Hint } from '../../ui/Hint';
import styles from './SignInForm.module.scss';
import { ResendCode } from '../common/ResendCode';

const CODE_LENGTH = 6;

type SignInFormPropsType = {
  loading: boolean;
  email?: string;
  phone?: string;
  step: number;
  onSubmit: (values: Inputs) => void;
  onResendEmail?: () => void;
  onResendSms?: () => void;
};

export const SignInForm: React.FC<SignInFormPropsType> = ({
  loading,
  email,
  phone,
  step,
  onSubmit,
  onResendEmail,
  onResendSms,
}) => {
  const [numButton, setNumButton] = useState(0);

  return (
    <Form
      initialValues={{
        email: '',
        code: '',
        phone_code: '',
        remember_me: false,
      }}
      className={styles.form}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(step)}
    >
      {({ setFieldValue, submitForm, submissionError, values }) => (
        <Flex direction="column" gap={[0, 12]}>
          <Block className={styles.block}>
            <Column gap={8}>
              {step === 0 && (
                <Column gap={24}>
                  <InputFormField
                    name="email"
                    label="Email"
                    placeholder="Work Email Address"
                  />
                  <CheckboxFormField
                    name="remember_me"
                    label={
                      <Flex alignItems="center" gap={[12]}>
                        Remember me for 30 days
                        <Hint content="If this box is unchecked, you will be asked to log in again after 24 hours of inactivity. By checking this box, you’ll stay logged in for 30 days regardless of activity." />
                      </Flex>
                    }
                  />
                </Column>
              )}
              {step === 1 && (
                <Column gap={48}>
                  <p className={styles.description}>
                    Enter the verification code sent to your email {email}
                  </p>
                  <Column gap={8}>
                    <Verification
                      autoFocus
                      validChars="0-9"
                      length={CODE_LENGTH}
                      onChange={(value) => {
                        setFieldValue('code', value);
                      }}
                      onComplete={submitForm}
                      value={submissionError ? '' : values.code}
                      error={!!submissionError}
                    />
                    <ResendCode onResend={onResendEmail} />
                  </Column>
                </Column>
              )}
              {step === 3 && (
                <Column gap={48}>
                  <p className={styles.description}>
                    Enter the verification code sent to your phone number{' '}
                    {phone}
                  </p>
                  <Column gap={8}>
                    <Verification
                      autoFocus
                      validChars="0-9"
                      length={CODE_LENGTH}
                      onChange={(value) => {
                        setFieldValue('phone_code', value);
                      }}
                      onComplete={submitForm}
                      value={submissionError ? '' : values.phone_code}
                      error={!!submissionError}
                    />
                    <ResendCode onResend={onResendSms} />
                  </Column>
                </Column>
              )}
              <FormSubmissionError className={styles.error} />
              {step === 0 && (
                <Flex
                  as="footer"
                  gap={[12]}
                  justify="center"
                  className={styles.footer}
                >
                  <Button
                    type="submit"
                    skin="secondary"
                    disabled={numButton === 0 && loading}
                    loading={numButton === 0 && loading}
                    onClick={() => setNumButton(0)}
                  >
                    Create account
                  </Button>
                  <Button
                    type="submit"
                    skin="primary"
                    disabled={numButton === 1 && loading}
                    loading={numButton === 1 && loading}
                    onClick={() => setNumButton(1)}
                  >
                    Next
                  </Button>
                </Flex>
              )}
            </Column>
          </Block>
          {step === 0 && (
            <>
              <p className={styles.description}>
                If you already have a professional profile in the AppClose
                mobile app, use the same email address. Otherwise, enter your
                professional email address and click ‘Create account’.
              </p>
              <p className={styles.description}>
                By signing up for AppClose, I agree to the{' '}
                <a href="https://appclose.com/terms.html" target="_blank">
                  AppClose Terms of Service Agreement
                </a>{' '}
                and{' '}
                <a href="https://appclose.com/privacy.html" target="_blank">
                  Privacy Policy
                </a>
              </p>
            </>
          )}
        </Flex>
      )}
    </Form>
  );
};
