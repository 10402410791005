import { FormGroup } from '../../../common/Form';
import { Fieldset } from '../../../common/Fieldset';
import { UpperFirstInputFormField } from '../../../fields/Input';
import { Block } from '../../../../ui/Block';
import { Column } from '../../../../ui/Column';
import FormGrid from '../../../common/FormGrid';
import { CountrySelectFormField } from '../../../fields/CountrySelectFormField';
import { USAStateSelectFormField } from '../../../fields/Select';
import { ProfileUpdateRequestData } from '../../../../types';
import { ZipCodeFormField } from '../../../fields/ZipCode';
import { USA_ZIP_CODE_MASK, ZIP_CODE_MASK } from '../../../../constants/masks';
import { useCallback } from 'react';

type CompanyAddressFieldsetPropsType = {
  values: ProfileUpdateRequestData;
  setValues: (values: React.SetStateAction<ProfileUpdateRequestData>) => void;
};

export function CompanyAddressFieldset({
  values,
  setValues,
}: CompanyAddressFieldsetPropsType) {
  const isUSCountry = values.company_info.country === 'US';

  const handleCountryChange = useCallback(
    (selectedCountry: string) => {
      if (selectedCountry !== 'US') {
        setValues((prevValues) => ({
          ...prevValues,
          company_info: {
            ...prevValues.company_info,
            state: '',
            zip_code: '',
          },
        }));
      }
    },
    [setValues]
  );

  const renderAddressFormFields = () => (
    <>
      <UpperFirstInputFormField
        name="company_info.address"
        label="Address"
        placeholder="Address"
        required
      />
      <UpperFirstInputFormField
        name="company_info.additional_address"
        label="Additional address"
        placeholder="Additional address"
      />
      <UpperFirstInputFormField
        name="company_info.city"
        label="City"
        placeholder="City"
        required
      />
    </>
  );

  return (
    <Block>
      <Fieldset title="Company address">
        <FormGroup>
          <Column gap={24}>
            <FormGrid gap={24}>
              <CountrySelectFormField
                disabled
                name="company_info.country"
                label="Country"
                placeholder="Country"
                required
                onChange={(selectedCountry: string) =>
                  handleCountryChange(selectedCountry)
                }
              />
              {isUSCountry ? (
                <>
                  <USAStateSelectFormField
                    name="company_info.state"
                    label="State"
                    placeholder="State"
                    required
                  />
                  {renderAddressFormFields()}
                  <ZipCodeFormField
                    name="company_info.postal_code"
                    mask={
                      values.company_info.country === 'US'
                        ? USA_ZIP_CODE_MASK
                        : ZIP_CODE_MASK
                    }
                    label="Zip code"
                    placeholder="Zip code"
                    required
                  />
                </>
              ) : (
                <>{renderAddressFormFields()}</>
              )}
            </FormGrid>
          </Column>
        </FormGroup>
      </Fieldset>
    </Block>
  );
}
