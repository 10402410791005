import { AxiosError } from 'axios';

export type CommonApiResponseErrorType = {
  message: string;
  errors?: { message?: string; path?: string }[];
};

export type SubmissionErrorType =
  | AxiosError<CommonApiResponseErrorType>
  | string
  | { message?: string }
  | null;

export type SubmissionErrorPropsType = {
  error?: SubmissionErrorType;
  className?: string;
  codeMessageMapping?: Record<number, string>;
};
