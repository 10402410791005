import { createContext, useCallback, useContext } from 'react';
import { SessionContext } from './Session';
import { QueryParamsContext } from './QueryParams';

type Props = {
  children: React.ReactNode;
};

type Fetch = (args: {
  url: string;
  params: { data?: any; options: Omit<RequestInit, 'body'> };
  errors?: Record<number, string>;
}) => Promise<any>;

export const FetchContext = createContext<Fetch>(null);

export const FetchProvider: React.FC<Props> = ({ children }) => {
  const session = useContext(SessionContext);
  const queryParams = useContext(QueryParamsContext);

  const call = useCallback<Fetch>(
    async ({ url, params, errors }) => {
      const { headers } = params.options;

      delete params.options.headers;

      const response = await window.fetch(process.env.API_URL + url, {
        body: JSON.stringify(params.data),
        headers: {
          'X-AppClose-Session-Token': session,
          'X-AppClose-AppName': queryParams['appName'] ?? undefined,
          'X-AppClose-Api-Version': process.env.API_VERSION,
          ...(headers || {}),
        },
        ...params.options,
      });

      if (!response.ok) {
        const text = await response.text();
        let body: Record<string, any>;

        try {
          body = JSON.parse(text);
        } catch {
          body = { error: text };
        }

        const errorDetails = {
          message:
            errors && errors[response.status]
              ? errors[response.status]
              : body.error,
          ...body,
        };

        throw errorDetails;
      }
      return response.json();
    },
    [session, queryParams]
  );

  return <FetchContext.Provider value={call}>{children}</FetchContext.Provider>;
};
