import { useEffect } from 'react';

import { FormSubmissionErrorPropsType } from './FormSubmissionError.types';
import useFormContext from '../../hooks/useFormContext';
import { SubmissionError, SubmissionErrorType } from '../../../SubmissionError';

export default function FormSubmissionError({
  className,
}: FormSubmissionErrorPropsType) {
  const { submissionError, values, setSubmissionError } = useFormContext();

  useEffect(() => {
    setSubmissionError(null);
  }, [values, setSubmissionError]);

  return (
    <SubmissionError
      error={submissionError as SubmissionErrorType}
      className={className}
    />
  );
}
