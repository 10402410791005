import usePaymentTypes from '../../../hooks/usePaymentTypes';
import { SelectFormField, SelectFormFieldPropsType } from '../Select';

export type PaymentTypeSelectFormFieldPropsType = {
  name?: string;
  label?: string;
} & Omit<SelectFormFieldPropsType<string>, 'options' | 'name' | 'label'>;

export default function PaymentTypeSelectFormField({
  name = 'paymentType',
  label = 'Payment type',
  placeholder = 'Select type...',
  ...rest
}: PaymentTypeSelectFormFieldPropsType) {
  const { paymentTypesOptions } = usePaymentTypes();

  return (
    <SelectFormField
      name={name}
      label={label}
      placeholder={placeholder}
      options={paymentTypesOptions}
      isMulti
      {...rest}
    />
  );
}
