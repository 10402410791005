.alignContentCenter {
  align-content: center;
}

.alignContentFlexEnd {
  align-content: flex-end;
}

.alignContentFlexStart {
  align-content: flex-start;
}

.alignContentSpaceAround {
  align-content: space-around;
}

.alignContentSpaceBetween {
  align-content: space-between;
}

.alignContentStretch {
  align-content: stretch;
}

.alignItemsCenter {
  align-items: center;
}

.alignItemsBaseline {
  align-items: baseline;
}

.alignItemsFlexEnd {
  align-items: flex-end;
}

.alignItemsFlexStart {
  align-items: flex-start;
}

.alignItemsStretch {
  align-items: stretch;
}

.displayFlex {
  display: flex;
}

.displayInlineFlex {
  display: inline-flex;
}

.flexDirectionRow {
  flex-direction: row;
}

.flexDirectionRowReverse {
  flex-direction: row-reverse;
}

.flexDirectionColumn {
  flex-direction: column;
}

.flexDirectionColumnReverse {
  flex-direction: column-reverse;
}

.flexWrapWrap {
  flex-wrap: wrap;
}

.flexWrapNowrap {
  flex-wrap: nowrap;
}

.flexWrapWrapReverse {
  flex-wrap: wrap-reverse;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentFlexEnd {
  justify-content: flex-end;
}

.justifyContentFlexStart {
  justify-content: flex-start;
}

.justifyContentSpaceAround {
  justify-content: space-around;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.justifyContentSpaceEvenly {
  justify-content: space-evenly;
}

.justifyContentStretch {
  justify-content: stretch;
}

.fluid {
  width: 100%;
  height: 100%;
}
