.loader {
  flex: 1 1 100%;
  width: 100%;
  min-height: 100%;
  background: transparent var(--loader-src) no-repeat center;
  background-size: var(--loader-width) var(--loader-height);
}

.loader::after {
  display: block;
  width: var(--loader-width);
  height: var(--loader-height);
  content: '';
}
