import { useRef } from 'react';
import classnames from 'classnames';

import { EllipsisPropsType } from './Ellipsis.types';
import styles from './Ellipsis.module.scss';

export default function Ellipsis({
  as: Component = 'p',
  lines = 1,
  className,
  children,
}: EllipsisPropsType) {
  const ref = useRef<HTMLElement>(null);

  if (!children) {
    return null;
  }

  return (
    <Component
      ref={ref}
      className={classnames(
        styles.ellipsis,
        {
          [styles.noBox]: lines <= 1,
        },
        className
      )}
      style={{ WebkitLineClamp: lines > 1 ? lines : undefined }}
    >
      {children}
    </Component>
  );
}
