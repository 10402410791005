import styles from './ProfileForm.module.scss';
import { AppclosePhoneTypes, ProfileUpdateRequestData } from '../../types';

import { Column } from '../../ui/Column';

import { CommonInfoFieldset } from './components/CommonInfoFieldset';
import { Form } from '../common/Form';
import { LicenseInfoFieldset } from './components/LicenseInfoFieldset';
import { CompanyInfoFieldset } from './components/CompanyInfoFieldset';
import { CompanyAddressFieldset } from './components/CompanyAddressFieldset';
import { PhoneNumbersInfoFieldset } from './components/PhoneNumbersInfoFieldset';
import { BrochuresCountInfoFieldset } from './components/BrochuresCountInfoFieldset';
import { ServicesInfoFieldset } from './components/ServicesInfoFieldset';
import { Button } from '../../ui/buttons';
import { ProfileFormSchema } from './ProfileForm.schema';
import FormSubmissionError from '../common/Form/components/FormSubmissionError';

type ProfilePersonalInfoFormPropsType = {
  onSubmit: (values: ProfileUpdateRequestData) => void;
  onSkip: () => void;
  loading: boolean;
};

export function ProfileForm({
  onSubmit,
  loading,
  onSkip,
}: ProfilePersonalInfoFormPropsType) {
  return (
    <Form
      initialValues={{
        personal: {
          firstname: '',
          lastname: '',
          country: 'US',
          city: '',
          zip_code: '',
          phone_number: '',
          phone_number_country: 'US',
          email: '',
        },
        company_info: {
          country: 'US',
          company: '',
          phone_number_type: 'work',
          phone_number_country: 'US',
          phone_number: '',
          city: '',
          postal_code: '',
          address: '',
          email: '',
        },
        services: {
          brochures_count: 10,
        },
      }}
      className={styles.form}
      validationSchema={ProfileFormSchema}
      onSubmit={onSubmit}
    >
      {({ values, setValues }) => {
        return (
          <Column gap={60}>
            <CommonInfoFieldset values={values} setValues={setValues} />
            <LicenseInfoFieldset />
            <CompanyInfoFieldset />
            <CompanyAddressFieldset values={values} setValues={setValues} />
            <PhoneNumbersInfoFieldset values={values} />
            <BrochuresCountInfoFieldset />
            <ServicesInfoFieldset />
            <footer className={styles.footer}>
              <FormSubmissionError />
              <div className={styles.buttons}>
                <Button type="submit" skin="primary" loading={loading}>
                  Save & finish
                </Button>
                {/* <Button onClick={onSkip}>Skip for now</Button> */}
              </div>
            </footer>
          </Column>
        );
      }}
    </Form>
  );
}
