import { useOptions } from './YearSelectFormField.utils';
import { useMemo, useState } from 'react';
import { debounce } from 'lodash';
import {
  SelectFormField,
  SelectFormFieldPropsType,
} from '../Select/components/SelectFormField';

export type YearSelectFormFieldPropsType = {
  name?: string;
  label?: string;
} & Omit<SelectFormFieldPropsType<string>, 'options' | 'name' | 'label'>;

export function YearSelectFormField({
  name = 'period',
  label,
  placeholder,
  ...props
}: YearSelectFormFieldPropsType) {
  const { options: optionsList } = useOptions();

  const [options, setOptions] = useState(optionsList);

  const onSearchChange = useMemo(
    () =>
      debounce((search: string = '') => {
        if (!search) {
          setOptions(optionsList);

          return;
        }

        const filtered = optionsList.filter((option) => {
          return option.label.toLowerCase().includes(search.toLowerCase());
        });

        setOptions(filtered);
      }, 500),
    [setOptions, optionsList]
  );

  return (
    <SelectFormField
      name={name}
      label={label || 'Year'}
      placeholder={placeholder || 'Select year'}
      withSearch
      onSearchChange={onSearchChange}
      {...props}
      options={options}
    />
  );
}
