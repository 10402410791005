.optionLoader {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.loader {
  flex: none;
  width: auto;
  min-width: auto;
}

.loadingLabel {
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;
}
