type FieldOwnTypes = {
  error?: any;
  fieldClassName?: string;
  label?: string;
  hidden?: boolean;
  hideErrorMsg?: boolean;
  required?: boolean;
};

export type FiledPropsType<ComponentType> = FieldOwnTypes &
  Omit<ComponentType, 'hasError'>;
